import { Navigate } from 'react-router-dom'
import { Statement } from 'admin/pages'
import { LoanBudgetDraw } from 'pages/LoanBudgetDraw/LoanBudgetDraw'
import { QuoteTermSheet } from 'pages/QuoteTermSheet/QuoteTermSheet'
import { Route } from 'routes/routes'
import {
  Applications,
  ApplicationDraft,
  ApplicationSubmitted,
  Documents,
  Document,
  Loans,
  Loan,
  LoanApplication,
  InvestorStatement,
  Transactions,
  Offerings,
  Dashboard,
  Receipt,
  GuestApplicationPage,
  Settings,
  PreviewApplicationPage,
  PublicDocuments,
  OfferingFund,
  OfferingLoan,
  OfferingIndividualFund,
  OfferingIndividualLoan,
} from './pages'
import { pathTo, routerPath } from './path-to'

export const routes: Route[] = [
  {
    path: routerPath.root,
    component: () => <Navigate replace to={routerPath.dashboard} />,
  },
  {
    path: routerPath.dashboard,
    component: () => <Dashboard />,
  },
  // Borrower
  {
    path: routerPath.applications,
    component: () => <Applications />,
  },
  {
    path: routerPath.applicationDraft,
    component: () => <ApplicationDraft />,
  },
  {
    path: routerPath.applicationSubmitted,
    component: () => <ApplicationSubmitted />,
  },
  {
    path: routerPath.applicationDocument,
    component: () => <Document />,
  },
  {
    path: [
      routerPath.loans,
      routerPath.loansTabActive,
      routerPath.loansTabPaidOff,
    ],
    component: () => <Loans />,
  },
  {
    path: [routerPath.loan, routerPath.loanTab, routerPath.loanTabEntityId],
    component: () => <Loan />,
  },
  {
    path: routerPath.loanDraw,
    component: () => <LoanBudgetDraw />,
  },
  {
    path: routerPath.receipt,
    isCustomLayout: true,
    component: () => <Receipt />,
  },
  {
    path: routerPath.statement,
    isCustomLayout: true,
    component: () => <Statement />,
  },
  {
    path: routerPath.investorStatement,
    isCustomLayout: true,
    component: () => <InvestorStatement />,
  },
  {
    path: routerPath.loanApplicationPrintable,
    isCustomLayout: true,
    component: () => <LoanApplication />,
  },
  {
    path: routerPath.loanDocument,
    component: () => <Document />,
  },
  // Investor
  {
    path: routerPath.documents,
    component: () => <Documents />,
  },
  {
    path: routerPath.investorDocument,
    component: () => <Document />,
  },
  {
    path: routerPath.transactions,
    component: () => <Transactions />,
  },
  {
    path: routerPath.offerings,
    component: () => <Offerings />,
  },
  {
    path: routerPath.offeringFund,
    component: () => <OfferingFund />,
  },
  {
    path: routerPath.offeringLoan,
    component: () => <OfferingLoan />,
  },
  {
    path: routerPath.offeringIndividualFund,
    component: () => <OfferingIndividualFund />,
  },
  {
    path: routerPath.offeringIndividualLoan,
    component: () => <OfferingIndividualLoan />,
  },
  // Shared
  {
    path: routerPath.settings,
    component: () => <Settings />,
  },
  // Quote
  {
    isPublic: true,
    path: routerPath.quoteTermSheet,
    component: () => <QuoteTermSheet />,
  },
  // Application
  {
    isPublic: true,
    path: routerPath.guestApplication,
    component: () => <GuestApplicationPage />,
  },
  {
    isPublic: true,
    path: routerPath.previewApplication,
    component: () => <PreviewApplicationPage />,
  },
  {
    path: '*',
    component: () => <Navigate to={pathTo('dashboard')} replace />,
  },
  // Documents
  {
    isPublic: true,
    path: routerPath.publicDocuments,
    component: () => <PublicDocuments />,
  },
]
