import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableReportCards } from 'admin/components/table/TableReportCards'
import {
  useCustomReports,
  useAddCustomReport,
  useUpdateCustomReport,
  useRemoveCustomReport,
} from 'admin/hooks/use-custom-report'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { CustomReport } from 'types'
import { ModalReport } from './ModalReport'
import { reportsCards } from './ReportCards'
import { TableReports } from './TableReports'

function Reports() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingReportId, setEditingReportId] = useState<string | undefined>(
    undefined
  )
  const navigate = useNavigate()
  const { visibleItems, result, sort, setSort, setPagination } =
    usePagination<CustomReport>({
      property: 'reports',
      useData: useCustomReports,
    })
  const { mutate: remove, isPending: isRemoving } = useRemoveCustomReport()
  const { mutate: add, isPending: isAdding } = useAddCustomReport()
  const { mutate: update, isPending: isUpdating } = useUpdateCustomReport()

  return (
    <MainContent>
      <PageTop title="Reports" />
      <TableReportCards data={reportsCards} />
      <div className="mt-6 border-0 border-b border-solid border-grey-200" />
      <Panel
        title="Custom Reports"
        className="mt-6"
        actionChildren={
          <Button variant="primary" onClick={() => setIsModalVisible(true)}>
            Create Custom Report
          </Button>
        }
      >
        <Flex gap={4} className="mb-4 -mt-4 text-grey-700">
          <Icon name={IconName.info} size="md" /> Custom reports are updated
          daily at 2am EST
        </Flex>
        <TableReports
          data={visibleItems}
          loading={result.isPending}
          sort={sort}
          onSort={setSort}
          onClick={(report) => {
            navigate(pathTo('reportCustom', report?.id as string))
          }}
          onEdit={(id) => {
            setEditingReportId(id)
            setIsModalVisible(true)
          }}
          onDelete={setRemovingId}
        />
        <LoadMore
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
      </Panel>
      {isModalVisible && (
        <ModalReport
          report={visibleItems.find(({ id }) => id === editingReportId)}
          saving={isAdding || isUpdating}
          onSave={(report) => {
            if (editingReportId) {
              update(
                { id: editingReportId as string, ...report },
                {
                  onSuccess: () => {
                    setEditingReportId(undefined)
                    setIsModalVisible(false)
                  },
                }
              )
            } else {
              add(report, {
                onSuccess: ({ id }) => {
                  setIsModalVisible(false)
                  navigate(pathTo('reportCustom', id))
                },
              })
            }
          }}
          onCancel={() => {
            setEditingReportId(undefined)
            setIsModalVisible(false)
          }}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="report"
          name={visibleItems?.find((report) => report.id === removingId)?.name}
          loading={isRemoving}
          onDelete={() =>
            remove(removingId, {
              onSettled: () => {
                setRemovingId(null)
              },
            })
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </MainContent>
  )
}

export { Reports }
