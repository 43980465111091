import { useCallback, useEffect, useState } from 'react'
import { NotificationEditor } from 'admin/components/InlineWysiwyg/NotificationEditor'
import { useSendDocument } from 'admin/hooks/use-document'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { useNotificationTemplate } from 'hooks/use-notification-settings'
import { NotificationSettingTemplate, Person } from 'types'

interface Props {
  documentId: string
  documentName: string
  signers: Person[]
  onCancel: () => void
  onComplete: () => void
}

const editorToTemplate = (body: string): string =>
  body?.replace(/<span data-lexical-variable="(.*?)">(.*?)<\/span>/gi, '{{$1}}')

const templateToEditor = (body: string): string =>
  body?.replace(/{{(.*?)}}/gi, '<span data-lexical-variable="$1">$1</span>')

const ModalSend = ({
  documentId,
  documentName,
  signers,
  onCancel,
  onComplete,
}: Props) => {
  const [editorKey, setEditorKey] = useState(0)
  const [isEditorVisible, setIsEditorVisible] = useState(false)
  const [template, setTemplate] = useState<NotificationSettingTemplate>()
  const { data: defaultTemplate, isLoading: loading } = useNotificationTemplate(
    'esignature/timetosign'
  )
  const { mutate: send, isPending: sending } = useSendDocument({
    documentId: documentId,
  })
  const handleRestoreToDefault = useCallback(() => {
    setEditorKey((currEditorKey) => currEditorKey + 1)
    setTemplate(defaultTemplate)
  }, [defaultTemplate])

  useEffect(() => {
    setTemplate(defaultTemplate)
  }, [defaultTemplate])

  return (
    <Modal
      title={`Send ${documentName}`}
      crossClose
      onClose={onCancel}
      className="w-[564px]"
    >
      {signers.length === 1 ? (
        <div>
          <Text variant="xs" className="font-bold text-grey-600 ml-2 mb-3">
            SEND TO
          </Text>
          <div className="border border-solid border-grey-200 px-3 py-2.5 rounded">
            <span className="font-bold">{signers[0].name}</span>{' '}
            {signers[0].email && (
              <span className="text-grey-800">&lt;{signers[0].email}&gt;</span>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Text variant="xs" className="font-bold text-grey-600 ml-2 mb-3">
            SEND SEQUENCE
          </Text>
          <div>
            {signers.map((signer, index) => (
              <Flex
                key={signer.id}
                alignItems="center"
                gap={8}
                className="border border-solid border-grey-200 border-b-0 px-3 py-2.5 first:rounded-t last:border-b last:rounded-b"
              >
                <Icon
                  name={
                    index === 0
                      ? IconName.arrowRightLong
                      : IconName.arrowNextLine
                  }
                  className="text-grey-600"
                />
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className="text-purple-200 bg-purple-25 h-4 w-4 rounded-full font-bold text-xs"
                >
                  {index + 1}
                </Flex>
                <span className="font-bold">{signer.name}</span>
                {signer.email && (
                  <span className="text-grey-800">&lt;{signer.email}&gt;</span>
                )}
              </Flex>
            ))}
          </div>
        </div>
      )}
      <div className="my-6">
        {isEditorVisible ? (
          <Flex stack gap={8}>
            <NotificationEditor
              key={editorKey}
              subject={templateToEditor(template?.subject as string)}
              body={templateToEditor(template?.body as string)}
              variables={
                template?.params?.map((param) => ({
                  value: param,
                  label: param,
                })) || []
              }
              onChangeSubject={(subject) => {
                setTemplate({
                  ...(template as NotificationSettingTemplate),
                  subject: editorToTemplate(subject),
                })
              }}
              onChangeBody={(body) => {
                setTemplate({
                  ...(template as NotificationSettingTemplate),
                  body: editorToTemplate(body),
                })
              }}
            />
            <Flex justifyContent="flex-end">
              <Button variant="ghost" onClick={handleRestoreToDefault}>
                <Icon name={IconName.reverse} />
                Restore to default
              </Button>
            </Flex>
          </Flex>
        ) : (
          <div
            className="inline text-grey-800 underline cursor-pointer"
            onClick={() => setIsEditorVisible(true)}
          >
            Edit message
          </div>
        )}
      </div>
      <Flex justifyContent="flex-end">
        <Button
          disabled={loading}
          loading={sending}
          onClick={() =>
            send(
              {
                subject: (template as NotificationSettingTemplate).subject,
                body: (template as NotificationSettingTemplate).body,
              },
              {
                onSuccess: onComplete,
              }
            )
          }
        >
          Send
        </Button>
      </Flex>
    </Modal>
  )
}

export { ModalSend }
