import { useState } from 'react'
import { useBanking, useUpdateBanking } from 'admin/hooks/use-banking'
import { Grid } from 'components/Grid'
import { ModalBanking } from 'components/Modal/Banking'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'

export const PanelBankingInfo = () => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const { data: banking, isPending } = useBanking()
  const { mutate: update, isPending: saving } = useUpdateBanking()

  return (
    <Panel
      title="NACHA Settings"
      onEdit={() => setIsEditModalVisible(true)}
      className="max-w-panel"
      loading={isPending}
    >
      <Grid gap={24}>
        <Grid.Item xs={6}>
          <Summary name="Account Name">{banking?.accountName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Account Number">{banking?.accountNumber}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Routing Number">{banking?.routingNumber}</Summary>
        </Grid.Item>

        <Grid.Item xs={6}>
          <Summary name="Account Type">{banking?.accountType}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Bank Name">{banking?.bankName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Immediate Origin">{banking?.immediateOrigin}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Immediate Origin Name">
            {banking?.immediateOriginName}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Reference Code">{banking?.referenceCode}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Name">{banking?.companyName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Standard Entry Class Code">
            {banking?.standardEntryClassCode}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Identification">
            {banking?.companyIdentification}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Entry Description">
            {banking?.companyEntryDescription}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Discretionary Data">
            {banking?.companyDiscretionaryData}
          </Summary>
        </Grid.Item>
      </Grid>
      {isEditModalVisible && (
        <ModalBanking
          banking={banking || null}
          saving={saving}
          onSave={(banking) =>
            update(banking, {
              onSuccess: () => {
                setIsEditModalVisible(false)
              },
            })
          }
          onCancel={() => setIsEditModalVisible(false)}
        />
      )}
    </Panel>
  )
}
