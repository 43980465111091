import { useParams } from 'react-router-dom'
import { MainContent } from 'borrower/components/layout/MainContent'
import { useApplication } from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Application as SubmittedApplication } from 'components/Application'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'

function ApplicationSubmitted() {
  const { id } = useParams() as { id: string }
  const { data: application } = useApplication(
    { id },
    { enabled: id !== 'new' }
  )

  return (
    <MainContent>
      {!application && <PageLoader />}
      {application && (
        <Flex className="" stack gap={16}>
          <PageTop
            title={`${application.name || `Application #${application.id}`}`}
            breadcrumbs={{
              title: 'Applications',
              link: pathTo('applications'),
            }}
            hideTopMenu
          />
          <SubmittedApplication application={application} />
        </Flex>
      )}
    </MainContent>
  )
}

export { ApplicationSubmitted }
