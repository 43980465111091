import { TableInvestorTransactions } from 'admin/components/table/TableInvestorTransactions'
import { MainContent } from 'borrower/components/layout/MainContent'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import PageTop from 'components/PageTop/PageTop'
import { useInvestorTransactions } from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Transaction } from 'types'

function Transactions() {
  const { user } = useSession()
  const investorId = user?.actor?.id as string
  const isInvestor = user?.actor?.isInvestor
  const { visibleItems, result, setPagination } = usePagination<Transaction>({
    property: 'transactions',
    useData: (params) =>
      useInvestorTransactions({
        ...params,
        isBorrower: !isInvestor,
        id: investorId,
      }),
  })

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Transactions" hideTopMenu={true} />
        <div>
          <TableInvestorTransactions
            data={visibleItems}
            isReferenceLink={false}
            loading={result.isPending}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </div>
      </Flex>
    </MainContent>
  )
}

export { Transactions }
