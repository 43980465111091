import clsx from 'clsx'
import { FieldArray, useFormikContext } from 'formik'
import { compact, isString } from 'lodash'
import { Phone, Select, FieldIcon, Date, Textarea } from 'components/Form'
import { CustomFieldProps } from 'components/Form/Field'
import { FormAddress } from 'components/FormAddress'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import styles from 'pages/GuestApplication/styles.module.scss'
import { ApplicationSchemeField, FieldType } from 'types'
import { BorrowerForm } from './BorrowerForm'
import { GuarantorForm } from './GuarantorForm'
import { SignatureField } from './SignatureField'
import { YesNoField } from './YesNoField'

type Props = CustomFieldProps & {
  prefix?: string
  disabled?: boolean
  field: ApplicationSchemeField
}

function ApplicationField({ prefix = '', disabled, field }: Props) {
  const name = compact([prefix, field.id]).join('.')
  const form = useFormikContext()

  switch (field.type) {
    case 'option':
      return (
        <Select
          name={name}
          label={field.label}
          options={[{ label: '(blank)', value: '' }, ...(field.options || [])]}
          placeholder={field.placeholder}
          bottomHint={field.description}
          disabled={disabled}
          portal
        />
      )
    case 'date':
      return (
        <Date
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          bottomHint={field.description}
          disabled={disabled}
        />
      )
    case 'phone':
      return (
        <Phone
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          bottomHint={field.description}
          disabled={disabled}
        />
      )
    case 'address':
      return (
        <FormAddress
          className={styles.addressLabels}
          prefix={name}
          disabled={disabled}
          heading={field.label}
          description={field.description}
        />
      )
    case 'borrower':
    case 'guarantor':
      return (
        <FieldArray
          name={name}
          render={(arrayHelpers) => {
            return (
              <Panel
                title={field.type === 'borrower' ? 'Borrowers' : 'Guarantors'}
                className={clsx(styles.panel, 'mb-8')}
              >
                {isString(arrayHelpers.form.errors[name]) && (
                  <div className="text-red-100 mb-4">
                    {arrayHelpers.form.errors[field.id] as string}
                  </div>
                )}

                {form.values?.[field.id]?.map((_, index) => {
                  if (field.type === 'guarantor') {
                    return (
                      <GuarantorForm
                        key={index}
                        prefix={`${field.id}.${index}`}
                        className={styles.personForm}
                        field={field}
                        disabled={disabled}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    )
                  }

                  return (
                    <BorrowerForm
                      key={index}
                      prefix={`${field.id}.${index}`}
                      className={styles.personForm}
                      field={field}
                      disabled={disabled}
                      onDelete={() => arrayHelpers.remove(index)}
                    />
                  )
                })}
                {!disabled && (
                  <div>
                    <TextLink
                      onClick={() => arrayHelpers.push({ type: '', name: '' })}
                    >
                      <Icon name={IconName.plus} size="sm" />
                      Add {field.type === 'borrower' ? 'Borrower' : 'Guarantor'}
                    </TextLink>
                  </div>
                )}
              </Panel>
            )
          }}
        />
      )
    case 'document':
      return <div />
    case 'yes-no':
      return (
        <YesNoField
          name={name}
          label={field.label}
          bottomHint={field.description}
          disabled={disabled}
        />
      )
    case 'signature':
      return <SignatureField field={field} disabled={disabled} />
    case 'tax-number':
    case 'ssn-number':
      return (
        <FieldIcon
          type="number"
          name={name}
          mask={field.type === 'ssn-number' ? '000-00-0000' : '00-0000000'}
          placeholder={field.placeholder}
          bottomHint={field.description}
          label={field.label}
        />
      )
    default:
      if (field.multiline) {
        return (
          <Textarea
            name={name}
            placeholder={field.placeholder}
            bottomHint={field.description}
            label={field.label}
            disabled={disabled}
          />
        )
      }
      return (
        <FieldIcon
          type={field.type as FieldType}
          name={name}
          placeholder={field.placeholder}
          bottomHint={field.description}
          label={field.label}
          disabled={disabled}
        />
      )
  }
}

export { ApplicationField }
