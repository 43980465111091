import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { Tabs } from 'components/Tabs'
import { DuePaymentsPanel } from './DuePaymentsPanel'
import { ProcessedPaymentsPanel } from './ProcessedPaymentsPanel'

const Payments = () => {
  const navigate = useNavigate()
  const { tab } = useParams() as { tab: 'due' | 'processed' }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Payments" />

        <Tabs
          tab={tab}
          onTabSelect={(tab) => {
            navigate(pathTo('payments', tab), { replace: true })
          }}
        >
          <Tabs.Pane tab="Due" id="due" className="pt-5">
            <DuePaymentsPanel />
          </Tabs.Pane>
          <Tabs.Pane tab="Processed" id="processed" className="pt-5">
            <ProcessedPaymentsPanel />
          </Tabs.Pane>
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { Payments }
