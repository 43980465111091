import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'borrower/components/layout/MainContent'
import { pathTo } from 'borrower/path-to'
import { ServicingLoanStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { useLoan } from 'hooks/use-loans'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import { TabDocuments } from './TabDocuments'
import { TabGeneral } from './TabGeneral'
import { TabPayments } from './TabPayment'
import { TabScopeOfWork } from './TabScopeOfWork'
import { TabStatements } from './TabStatements'
import styles from './styles.module.scss'

export const Loan = () => {
  const { id, tab } = useParams() as { id: string; tab: string }
  const { data: loan } = useLoan({ id })
  const navigate = useNavigate()
  const breadcrumbs = { title: 'Loans', link: pathTo('loans') }
  const settings = useMemo(() => {
    return {
      showBudget: loan?.settings?.showBudget ?? true,
      showDrawRequests: loan?.settings?.showDrawRequests ?? true,
    }
  }, [loan])

  return (
    <MainContent>
      {loan ? (
        <Flex stack gap={24}>
          <Flex stack gap={8}>
            <PageTop
              title={loan.name}
              breadcrumbs={breadcrumbs}
              hideTopMenu={true}
            />
            <div>
              <ServicingLoanStatusBadge
                status={loan.status}
                daysPastDue={loan.daysPastDue}
                daysLate={loan.daysLate}
                dateDefault={loan.dateDefault}
              />
            </div>
          </Flex>
          <PageTopFields
            fields={[
              { key: 'ID', value: loan.id },
              {
                key: 'Current Principal',
                value: formatUsd(loan.principalBalance),
              },
              {
                key: 'Rate',
                value: formatPercent(loan.rate, { maxDecimals: 3 }),
              },
              { key: 'Regular Payment', value: formatUsd(loan.regularPayment) },
            ]}
          />
          <Tabs
            key={tab}
            defaultActiveId={tab || 'general'}
            onTabSelect={(tabId) => navigate(pathTo('loanTab', id, tabId))}
            className={styles.tabs}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral loan={loan} />
            </Tabs.Pane>
            <Tabs.Pane tab="Payments" id="payments">
              <TabPayments loan={loan} />
            </Tabs.Pane>
            <Tabs.Pane tab="Documents" id="documents">
              <TabDocuments loan={loan} />
            </Tabs.Pane>
            <Tabs.Pane tab="Statements" id="statements">
              <TabStatements loan={loan} />
            </Tabs.Pane>
            {settings.showBudget ? (
              <Tabs.Pane
                tab="Scope of Work"
                id="scopeOfWork"
                className={styles.tabWithNavigation}
              >
                <TabScopeOfWork
                  loan={loan}
                  isDrawRequestVisible={settings.showDrawRequests}
                />
              </Tabs.Pane>
            ) : null}
          </Tabs>
        </Flex>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}
