import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo, pathToLoan } from 'admin/path-to'
import { downloadLoansReport } from 'admin/services/csv/download-loans'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { useLoans } from 'hooks/use-loans'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'
import ArchivedLoanReportTable from './ArchivedLoanReportTable'

type RouterParams = Readonly<{
  id: string
}>

function ArchivedLoanReport() {
  const navigate = useNavigate()
  const { id } = useParams() as RouterParams

  let title = 'Report'
  let statusFilter
  switch (id) {
    case 'servicing-loans':
      title = 'Sent to Servicing'
      statusFilter = 'servicing'
      break
    case 'archived-loans':
      title = 'Archived Loans'
      statusFilter = 'archived'
      break
    case 'liquidated-loans':
      title = 'Liquidated Loans'
      statusFilter = 'liquidated'
      break
  }

  const { visibleItems, result, setPagination } = usePagination<Loan>({
    property: 'loans',
    useData: (params) =>
      useLoans(
        {
          ...params,
          filter: statusFilter ? { status: [statusFilter] } : undefined,
        },
        {
          keepPreviousData: true,
        }
      ),
  })

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title={title}
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download
            filename="loans"
            download={() =>
              downloadLoansReport({
                status: [statusFilter],
              })
            }
          />
        </PageTop>
        <Panel>
          <ArchivedLoanReportTable
            data={visibleItems}
            loading={result.isPending}
            statusFilter={statusFilter}
            onClick={(loan) => {
              if (loan) {
                navigate(pathToLoan(loan))
              }
            }}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { ArchivedLoanReport }
