import clsx from 'clsx'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Loan, Person } from 'types'
import { PersonSelect } from './PersonSelect'

interface Props {
  index: number
  loan: Loan | undefined
  person: Person | undefined
  onSelect: (person: Person) => void
  onDelete: () => void
  onDrop: (dragIndex: number, dropIndex: number) => void
}

export const PersonOption = ({
  index,
  loan,
  person,
  onSelect,
  onDelete,
  onDrop,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['item'],
      drop: (item: { person: Person; index: number }) => {
        onDrop(item.index, index)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    []
  )
  const [{ isDragging }, drag] = useDrag(
    {
      type: 'item',
      item: () => ({
        index,
        person,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index, person]
  )

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      gap={10}
      alignItems="flex-end"
      className={clsx(
        'border-0 border-b-[3px] border-solid border-white-100 bg-white-100 group',
        isOver && '!border-blue-100',
        isDragging && 'hidden'
      )}
    >
      <Icon
        name={IconName.dndHandler}
        className="text-grey-600 flex-shrink-0 p-2 w-8 h-8 cursor-move mb-1.5"
      />
      <Flex stack gap={4} className="flex-grow">
        <Text>Signer {index + 1}</Text>
        <PersonSelect loan={loan} person={person} onSelect={onSelect} />
      </Flex>
      <Button
        variant="ghost"
        icon
        onClick={onDelete}
        className={clsx(
          'invisible group-hover:visible mb-1.5',
          (isOver || isDragging) && 'group-hover:invisible'
        )}
      >
        <Icon name={IconName.delete} className="text-grey-600" />
      </Button>
    </Flex>
  )
}
