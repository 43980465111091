import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { PanelTemplates } from './PanelTemplates'

function DocumentTemplates() {
  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Templates"
        />

        <PanelTemplates />
      </Flex>
    </MainContent>
  )
}

export { DocumentTemplates }
