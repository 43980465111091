import { useFormikContext } from 'formik'
import { get, isUndefined } from 'lodash'
import { useEffect } from 'react'
import { AccountTypeBadge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { TextLink } from 'components/TextLink'
import { ApplicationSchemeField, PersonType } from 'types'
import { ApplicationField } from './ApplicationField'
import { PersonFormType } from './PersonFormType'

interface Props {
  prefix: string
  field: ApplicationSchemeField
  className?: string
  onDelete?: () => void
}

const PersonForm = ({ prefix, field, className, onDelete }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const type = get(values, `${prefix}.type`)

  useEffect(() => {
    const formValues = get(values, prefix)
    if (formValues.type === 'individual') {
      field.individualFields?.forEach((individualField) => {
        if (isUndefined(formValues[individualField.id])) {
          setFieldValue(`${prefix}.${individualField.id}`, '')
        }
      })
    }
    if (formValues.type === 'entity') {
      field.entityFields?.forEach((entityField) => {
        if (isUndefined(formValues[entityField.id])) {
          setFieldValue(`${prefix}.${entityField.id}`, '')
        }
      })
    }
  }, [setFieldValue, prefix, values, type])

  return (
    <div className={className}>
      {!type ? (
        <PersonFormType prefix={prefix} />
      ) : (
        <Flex
          stack
          className="mb-6 border-0 border-b border-solid border-grey-100"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <AccountTypeBadge type={type as PersonType} />
              <TextLink onClick={() => setFieldValue(prefix, { type: '' })}>
                Change type
              </TextLink>
            </Flex>
            {onDelete && (
              <Button size="small" color="negative" onClick={() => onDelete()}>
                Delete
              </Button>
            )}
          </Flex>
          <div>
            {type === 'individual'
              ? field.individualFields
                  ?.filter(({ enabled }) => enabled)
                  .map((field) => (
                    <ApplicationField
                      key={field.id}
                      prefix={prefix}
                      field={field}
                    />
                  ))
              : field.entityFields
                  ?.filter(({ enabled }) => enabled)
                  .map((field) => (
                    <ApplicationField
                      key={field.id}
                      prefix={prefix}
                      field={field}
                    />
                  ))}
          </div>
        </Flex>
      )}
    </div>
  )
}

export { PersonForm }
