import { get } from 'lodash'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { NotificationSettings } from 'components/NotificationSettings'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import {
  useClientNotificationSettings,
  useUpdateClientNotificationSettings,
} from 'hooks/use-notification-settings'
import { useSession } from 'hooks/use-session'

function Notifications() {
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Notifications"
        />
        <Panel className="max-w-panel">
          <NotificationSettings
            editable
            useNotificationSettings={() =>
              useClientNotificationSettings(clientId)
            }
            useUpdateNotificationSettings={() =>
              useUpdateClientNotificationSettings(clientId)
            }
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { Notifications }
