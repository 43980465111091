import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFundDocuments } from 'admin/hooks/use-fund-documents'
import { useFund } from 'admin/hooks/use-funds'
import { MainContent } from 'borrower/components/layout/MainContent'
import { PanelMyInvestments } from 'borrower/pages/Offering/PanelMyInvestments'
import { TabDocuments } from 'borrower/pages/Offering/TabDocuments'
import { TabPortfolio } from 'borrower/pages/Offering/TabPortfolio'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Indicator } from 'components/Indicator'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'

function OfferingIndividualFund() {
  const [tab, setTab] = useState('fund-portfolio')
  const { fundId } = useParams() as { fundId: string }
  const { user } = useSession()
  const { data: fund } = useFund(fundId)
  const { data: documents, isLoading: isDocumentsLoading } = useFundDocuments({
    id: fundId as string,
  })

  return (
    <MainContent>
      {fund ? (
        <Flex stack gap={24}>
          <Panel className="p-6 sm:p-12">
            <Flex stack gap={16}>
              <div className="text-grey-600 font-bold text-sm uppercase">
                {user?.client.name}
              </div>
              <Header className="truncate md:whitespace-normal text-[44px]">
                {fund.fund.name}
              </Header>
              <Flex
                gap={8}
                alignItems="center"
                className="text-grey-900 text-5xl"
              >
                <Indicator
                  color="green"
                  className="w-2.5 h-2.5 flex-shrink-0 rounded-[3px] bg-green-50"
                />
                Performing
              </Flex>
            </Flex>
          </Panel>
          <Panel className="p-6 sm:p-12">
            <Flex stack gap={54}>
              {!!fund.fundingSources?.length && (
                <PanelMyInvestments fundingSources={fund.fundingSources} />
              )}

              {fund.fund.showPortfolio && (
                <Flex
                  stack
                  className="px-4 py-5 border border-solid border-grey-200 rounded"
                >
                  <Flex gap={8}>
                    <Button
                      variant="panel"
                      active={tab === 'fund-portfolio'}
                      onClick={() => setTab('fund-portfolio')}
                    >
                      Fund Portfolio
                    </Button>
                  </Flex>
                  {tab === 'fund-portfolio' && <TabPortfolio fundId={fundId} />}
                </Flex>
              )}
            </Flex>
          </Panel>
          {!!documents?.length && (
            <Panel className="p-6 sm:p-12">
              <Flex stack gap={16}>
                <Header variant="h2">Summary</Header>
                <Flex
                  stack
                  className="px-4 py-5 border border-solid border-grey-200 rounded"
                >
                  <Flex gap={8}>
                    <Button variant="panel" active={true}>
                      Documents
                    </Button>
                  </Flex>
                  <TabDocuments
                    documents={documents}
                    loading={isDocumentsLoading}
                  />
                </Flex>
              </Flex>
            </Panel>
          )}
        </Flex>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { OfferingIndividualFund }
