import { get } from 'lodash'
import { useState } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateCurrentClient } from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { ModalProfileDomainEdit } from 'components/Modal/ProfileDomainEdit'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import { BusinessProfileDomain } from './BusinessProfileDomain'
import { ClientImage } from './ClientImage'

function Workspace() {
  const { user } = useSession()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const name = get(user, 'client.name', '')
  const subdomain = get(user, 'client.subdomain', '')
  const clientId = get(user, 'client.id', '')
  const paymentsEnabled = get(user, 'client.paymentsEnabled', false)
  const { mutate: update } = useUpdateCurrentClient()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Workspace"
        />

        <Panel
          title="Workspace"
          className="max-w-panel"
          onEdit={() => setIsModalVisible(true)}
        >
          <Grid gap={12}>
            <Grid.Item sm={12} md={12}>
              <BusinessProfileDomain
                name={name}
                subdomain={subdomain}
                paymentsEnabled={paymentsEnabled}
              />
            </Grid.Item>
            <Grid.Item sm={12} md={12}>
              <ClientImage />
            </Grid.Item>
          </Grid>
        </Panel>
      </Flex>
      {isModalVisible ? (
        <ModalProfileDomainEdit
          onCancel={() => setIsModalVisible(false)}
          onSubmit={({ name, subdomain }) =>
            update(
              { name, subdomain, clientId },
              { onSuccess: () => setIsModalVisible(false) }
            )
          }
          saving={false}
          name={name}
          subdomain={subdomain}
        />
      ) : null}
    </MainContent>
  )
}

export { Workspace }
