import { orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
  useApplications,
  useDraftApplications,
} from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import PageTop from 'components/PageTop/PageTop'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import { ApplicationsTiles } from './ApplicationsTiles'
import { NoEntities } from './NoEntities'

export const Applications = () => {
  const navigate = useNavigate()
  const { user, isManager } = useSession()
  const isPersonalAccount =
    user?.actor?.id === user?.person?.id && user?.person?.isAccount
  const isEditable = isManager || isPersonalAccount
  const {
    data: submittedApplications,
    isLoading: isLoadingSubmittedApplications,
  } = useApplications()
  const { data: draftApplications, isLoading: isLoadingDraftApplications } =
    useDraftApplications()
  const handleAddApplication = () => {
    navigate(pathTo('applicationDraft', 'new'))
  }

  const isLoading = isLoadingSubmittedApplications || isLoadingDraftApplications
  const applications = orderBy(
    [
      ...(submittedApplications?.map((application) => ({
        ...application,
        isDraft: false,
      })) || []),
      ...(draftApplications?.map((application) => ({
        ...application,
        isDraft: true,
      })) || []),
    ],
    ['createdAt', 'name'],
    ['desc', 'asc']
  )
  const isEmpty = applications.length === 0

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex stack gap={48}>
          <PageTop title="My Applications" hideTopMenu>
            {isEmpty ? (
              <TextLink
                onClick={() => navigate(pathTo('applications'))}
                className="!text-blue-100"
              >
                View Past Applications
              </TextLink>
            ) : (
              <Flex gap={28} alignItems="center">
                <TextLink
                  onClick={() => navigate(pathTo('applications'))}
                  className="!text-blue-100"
                >
                  View All Applications
                </TextLink>
                {isEditable && (
                  <Button
                    variant="primary"
                    size="large"
                    onClick={handleAddApplication}
                    className="rounded-full"
                  >
                    Add Application
                  </Button>
                )}
              </Flex>
            )}
          </PageTop>
          {isEmpty ? (
            <NoEntities
              iconName={IconName.documentPlus}
              title="You don’t have any active applications"
              subtitle={
                isEditable &&
                'Click the button below to start a new loan application.'
              }
              action={
                isEditable && (
                  <Button
                    variant="primary"
                    size="large"
                    onClick={handleAddApplication}
                    className="w-min rounded-full"
                  >
                    Add Application
                  </Button>
                )
              }
            />
          ) : (
            <ApplicationsTiles
              applications={applications}
              isEditable={isEditable}
            />
          )}
        </Flex>
      )}
    </>
  )
}
