import { get } from 'lodash'
import { PanelLenderOfRecord } from 'admin/components/PanelLenderOfRecord'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { useSession } from 'hooks/use-session'

function LenderOfRecord() {
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Lenders of Record"
        />
        <PanelLenderOfRecord clientId={clientId} />
      </Flex>
    </MainContent>
  )
}

export { LenderOfRecord }
