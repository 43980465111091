import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableOfferings } from 'admin/components/table/TableOfferings'
import {
  useAddOffering,
  useOfferings,
  useDeleteOffering,
} from 'admin/hooks/use-offerings'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalAddOffering } from 'components/Modal/AddOffering'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { Offering } from 'types'
import { EmptyOfferings } from './EmptyOfferings'

const Offerings = () => {
  const {
    visibleItems,
    result,
    sort,
    isEmpty,
    setSort,
    search,
    setSearch,
    setPagination,
  } = usePagination<Offering>({
    property: 'offerings',
    useData: (params) => useOfferings(params),
  })
  const [adding, setAdding] = useState(false)
  const [deletingOffering, setDeletingOffering] = useState<Offering>()
  const navigate = useNavigate()
  const { mutate: add, isPending: isAdding } = useAddOffering()
  const { mutate: remove, isPending: isRemove } = useDeleteOffering()

  const handleRowClick = (offering?: Offering) => {
    if (offering?.id) {
      navigate(pathTo('offering', offering.id))
    }
  }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Offerings" />
        {isEmpty ? (
          <EmptyOfferings onClick={() => setAdding(true)} />
        ) : (
          <Panel>
            <div className="flex justify-center gap-4 pb-4 sm:justify-end">
              <Search search={search} onSearch={setSearch} />
              <Button variant="primary" onClick={() => setAdding(true)}>
                Add Offering
              </Button>
            </div>
            <TableOfferings
              data={visibleItems}
              sort={sort}
              onSort={setSort}
              loading={result.isPending}
              onClick={handleRowClick}
              onDelete={(offering) => setDeletingOffering(offering)}
            />
            <LoadMore
              loading={result.isPending}
              fetching={result.isFetching}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>
      {adding ? (
        <ModalAddOffering
          saving={isAdding}
          onSave={(offering) => {
            add(offering, {
              onSuccess: ({ id }) => {
                setAdding(false)
                navigate(pathTo('offering', id))
              },
            })
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
      {deletingOffering && (
        <ModalDelete
          resource="offering"
          loading={isRemove}
          onDelete={() =>
            remove(deletingOffering.id, {
              onSuccess: () => setDeletingOffering(undefined),
            })
          }
          onCancel={() => setDeletingOffering(undefined)}
        />
      )}
    </MainContent>
  )
}

export { Offerings }
