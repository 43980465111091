import { useCallback } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportTax1099 } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { download1099TaxReport } from 'admin/services/csv/download-tax-report'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import TableTax1099 from './TableTax1099'

function Tax1099Report() {
  const { visibleItems, result, filter, sort, search, setSort, setPagination } =
    usePagination<any>({
      property: 'investors',
      useData: (tableProps) => useReportTax1099('1099-tax', tableProps),
    })
  const handleDownload = useCallback(
    () => download1099TaxReport({ filter, sort, search }),
    [filter, sort, search]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="1099 Tax Report"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download filename="1099" download={handleDownload} />
        </PageTop>
        <Panel>
          <TableTax1099
            data={visibleItems}
            loading={result.isPending}
            sort={sort}
            onSort={setSort}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { Tax1099Report }
