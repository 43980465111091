import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableMembers } from 'admin/components/table/TableMembers'
import {
  useUsers,
  useAddUser,
  useUpdateUser,
  useRemoveUser,
} from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { subdomain } from 'constants/domain'
import { useSession } from 'hooks/use-session'
import { CurrentUser, resetPassword } from 'services/api/session'
import { ModalMember } from './ModalMember'

function Members() {
  const [isUserModalVisible, setIsUserModalVisible] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { user } = useSession()
  const { data, isPending } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })
  const { mutate: add, isPending: isAdding } = useAddUser({
    clientId: (user as CurrentUser)?.client?.id,
  })
  const { mutate: update, isPending: isUpdating } = useUpdateUser({
    clientId: (user as CurrentUser)?.client?.id,
  })
  const { mutate: reinvite } = useMutation({ mutationFn: resetPassword })
  const { mutate: remove, isPending: isRemoving } = useRemoveUser({
    clientId: (user as CurrentUser)?.client?.id,
  })

  const handleSubmit = useCallback(
    (user) => {
      const options = {
        onSuccess: () => {
          setEditingId(null)
          setIsUserModalVisible(false)
        },
      }

      if (editingId) {
        update({ userId: editingId, ...user }, options)
      } else {
        add(user, options)
      }
    },
    [update, add, editingId]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Team"
        />
        <Panel
          title="Members"
          actionChildren={
            <Button
              variant="primary"
              onClick={() => setIsUserModalVisible(true)}
            >
              Add Member
            </Button>
          }
        >
          <TableMembers
            data={data}
            loading={isPending}
            onEdit={(id) => {
              setIsUserModalVisible(true)
              setEditingId(id)
            }}
            onChangePermission={(userId, permission) =>
              update({ userId, permission })
            }
            onDelete={(id) => setRemovingId(id)}
            onReinvite={(email) =>
              reinvite({
                email,
                subdomain,
              })
            }
          />
        </Panel>
        {isUserModalVisible && (
          <ModalMember
            user={data?.find((user) => user.id === editingId)}
            saving={isAdding || isUpdating}
            onSubmit={handleSubmit}
            onCancel={() => {
              setEditingId(null)
              setIsUserModalVisible(false)
            }}
          />
        )}
        {removingId && (
          <ModalDelete
            resource="member"
            loading={isRemoving}
            onDelete={() =>
              remove(
                { userId: removingId },
                {
                  onSuccess: () => {
                    setRemovingId(null)
                  },
                }
              )
            }
            onCancel={() => setRemovingId(null)}
          />
        )}
        {}
      </Flex>
    </MainContent>
  )
}

export { Members }
