import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import PageTop from 'components/PageTop/PageTop'
import { TextLink } from 'components/TextLink'
import { useLoans } from 'hooks/use-loans'
import { LoansTiles } from './LoansTiles'
import { NoEntities } from './NoEntities'

export const Loans = () => {
  const navigate = useNavigate()
  const { data, isPending } = useLoans({
    filter: { status: ['closed', 'servicing'] },
  })
  const isEmpty = !data?.loans.length

  return (
    <div className="mt-20">
      {isPending ? (
        <PageLoader />
      ) : (
        <Flex stack gap={48}>
          <PageTop title="My Active Loans" hideTopMenu>
            {isEmpty ? (
              <TextLink
                onClick={() => navigate(pathTo('loan'))}
                className="!text-blue-100"
              >
                View Past Loans
              </TextLink>
            ) : (
              <Flex gap={28} alignItems="center">
                <TextLink
                  onClick={() => navigate(pathTo('loan'))}
                  className="!text-blue-100"
                >
                  View All Loans
                </TextLink>
              </Flex>
            )}
          </PageTop>
          {isEmpty ? (
            <NoEntities
              iconName={IconName.documentDollar}
              title="You don’t have any active loans"
              subtitle="Once your loans are active, they will be accessible here."
            />
          ) : (
            <LoansTiles loans={data.loans} />
          )}
        </Flex>
      )}
    </div>
  )
}
