import { isNil, get } from 'lodash'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInvestor } from 'admin/hooks/use-investors'
import { useCashBalancesReport } from 'admin/hooks/use-report'
import {
  useInvestorCashBalance,
  useInvestorPrincipalInvestedBalance,
} from 'borrower/hooks/use-investor-statements'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { Logo } from 'components/Logo'
import { useClient } from 'hooks/use-client'
import {
  useInvestorFundingSources,
  useInvestorTransactions,
} from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Transaction } from 'types'
import { formatAddress } from 'utils/address'
import { formatUsd } from 'utils/currency'
import { formatDate, friendlyDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'
import { subDecimal, sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

function InvestorStatement() {
  const { user } = useSession()
  const { data: client, isFetching } = useClient({
    enabled: !user,
  })
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })
  const showBalance = settings.autoInvestorAccounting !== 'Yes'
  const data = user?.client || client
  const footer = data?.settings?.investorStatementFooter
  const showTotalCash = data?.settings?.autoInvestorAccounting !== 'Yes'

  const [isLogoLoaded, setIsLogoLoaded] = useState(false)
  const { investorId, from, to } = useParams() as {
    investorId: string
    from: string
    to: string
  }
  const personId = user?.actor?.id || investorId

  const { data: investor } = useInvestor({ id: investorId as string })
  const { data: fundingSources } = useInvestorFundingSources({
    investorId: investorId as string,
    params: { from, date: to, hideOldLiquidated: true },
  })
  const { data: investorCashBalance } = useInvestorCashBalance({
    investorId: investorId as string,
    date: to,
  })
  const { data: actorCashBalance } = useCashBalancesReport(
    `investor/${personId}/cash-balances`,
    { date: to }
  )

  const { visibleItems: transactions, result } = usePagination<Transaction>({
    property: 'transactions',
    useData: (params) =>
      useInvestorTransactions({
        ...params,
        isBorrower: false,
        id: investorId as string,
        filter: { date: [['between', from, to]] },
        pagination: { page: 0, size: 1_000 },
      }),
  })

  const { data: principalInvestedBalance } =
    useInvestorPrincipalInvestedBalance({
      investorId: investorId as string,
      date: to,
    })

  const totalIncome = useMemo(
    () =>
      sumDecimal(fundingSources?.map(({ interestBalance }) => interestBalance)),
    [fundingSources]
  )
  const totalBalance = useMemo(
    () => sumDecimal(fundingSources?.map(({ amount }) => amount)),
    [fundingSources]
  )

  const transactionsBalanced = useMemo(() => {
    let runningBalance = actorCashBalance?.cash || 0
    return transactions.map((item) => {
      runningBalance = subDecimal(runningBalance, item.amount)
      return {
        ...item,
        balance: sumDecimal([runningBalance, item.amount]),
      }
    })
  }, [transactions, actorCashBalance])

  const isLoaded =
    investor &&
    fundingSources &&
    !isNil(investorCashBalance) &&
    !isNil(principalInvestedBalance) &&
    !isFetching &&
    !isNil(actorCashBalance) &&
    result.isFetched

  return isLoaded ? (
    <div className={styles.body} data-all-loaded={isLoaded && isLogoLoaded}>
      <div className="max-w-[700px] mx-auto pb-8 bg-white-100">
        <Flex gap={10} className="px-8 py-[30px]">
          <Flex className="flex-[45%]">
            <div>
              <Logo to="/" color="blue" onLoad={() => setIsLogoLoaded(true)} />
            </div>
          </Flex>
          <Flex
            stack
            gap={6}
            alignItems="flex-end"
            justifyContent="space-between"
            className="flex-[55%] text-right"
          >
            <div className="py-0.5 text-[25px] print:text-[22px] font-light">
              Investment Report
            </div>
            <div className="py-0.5 text-[12px] print:text-[9px] text-grey-700 normal-nums">
              {`${formatDate(from)} - ${formatDate(to)}`}
            </div>
          </Flex>
        </Flex>
        <div className="px-4">
          <Flex gap={44} className="bg-grey-50 px-4 pt-4 rounded-[10px]">
            <Flex stack gap={0} className="w-full pb-2.5">
              <div className="text-[13px] print:text-[10px] text-grey-900 font-bold">
                Portfolio Summary
              </div>
              <Flex
                stack
                gap={5}
                className="mt-3 pb-4 text-[11px] print:text-[8px] text-grey-900"
              >
                {showTotalCash && (
                  <>
                    <Flex justifyContent="space-between">
                      <div>Total Cash</div>
                      <div>
                        {formatUsd(actorCashBalance.cash, { showZero: true })}
                      </div>
                    </Flex>

                    <Flex
                      justifyContent="space-between"
                      className="text-grey-700"
                    >
                      <div className="ml-2.5">Available Cash</div>
                      <div>
                        {formatUsd(actorCashBalance.availableCash, {
                          showZero: true,
                        })}
                      </div>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      className="text-grey-700"
                    >
                      <div className="ml-2.5">Committed Cash</div>
                      <div>
                        {formatUsd(
                          subDecimal(
                            actorCashBalance.cash,
                            actorCashBalance.availableCash
                          ),
                          {
                            showZero: true,
                          }
                        )}
                      </div>
                    </Flex>
                  </>
                )}
                <Flex justifyContent="space-between" className="py-[3px]">
                  <div>Investment Balance</div>
                  <div>
                    {formatUsd(principalInvestedBalance, { showZero: true })}
                  </div>
                </Flex>
                <div className="border-0 border-b border-solid border-grey-300" />
                <Flex justifyContent="space-between" className="py-[3px]">
                  <div>Portfolio Value</div>
                  <div>
                    {formatUsd(
                      sumDecimal([
                        investorCashBalance,
                        principalInvestedBalance,
                      ]),
                      {
                        showZero: true,
                      }
                    )}
                  </div>
                </Flex>
                <div className="border-0 border-b border-solid border-grey-300" />
              </Flex>
            </Flex>

            <div className="flex-[35%] text-grey-900">
              <div className="text-[13px] print:text-[10px] font-bold">
                {investor.name}
              </div>
              <div className="whitespace-pre-wrap text-[11px] print:text-[8px] mt-2">
                {investor.addresses
                  ?.filter(({ type }) => type === 'primary')
                  .map((address) => (
                    <div key={address.id}>
                      {formatAddress(address, { multiline: true })}
                    </div>
                  ))}
              </div>
            </div>
          </Flex>
        </div>
        <Flex stack className="my-7 px-4">
          <Flex stack gap={8} className="mb-4">
            <Flex
              alignItems="center"
              gap={8}
              className="text-[13px] print:text-[10px]"
            >
              <div className="text-grey-900 font-bold">Portfolio</div>
            </Flex>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Investment</th>
                  <th>Class</th>
                  <th>Balance</th>
                  <th>Income</th>
                </tr>
              </thead>
              <tbody>
                {fundingSources.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name || item.loan?.name}</td>
                    <td>{item.class || '-'}</td>
                    <td>{formatUsd(item.amount, { showZero: true })}</td>
                    <td>
                      {formatUsd(item.interestBalance, { showZero: true })}
                    </td>
                  </tr>
                ))}
                <tr className="bg-grey-50 font-bold">
                  <td>Total</td>
                  <td></td>
                  <td>{formatUsd(totalBalance, { showZero: true })}</td>
                  <td>{formatUsd(totalIncome, { showZero: true })}</td>
                </tr>
              </tbody>
            </table>
          </Flex>
        </Flex>

        <Flex stack className="my-7 px-4">
          <Flex stack gap={8} className="mb-4">
            <Flex
              alignItems="center"
              gap={8}
              className="text-[13px] print:text-[10px]"
            >
              <div className="text-grey-900 font-bold">
                Transaction Activity
              </div>
              <div className="text-grey-700 normal-nums">
                {friendlyDate(from)} &mdash; {friendlyDate(to)}
              </div>
            </Flex>

            {transactionsBalanced.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    {showBalance && <th>Balance</th>}
                  </tr>
                </thead>
                <tbody>
                  {transactionsBalanced.map((item) => (
                    <tr key={item.id}>
                      <td>{formatDate(item.date)}</td>
                      <td>{getFundingType(item)}</td>
                      <td>
                        {item.loan?.name || item.fund?.name || ''}{' '}
                        {item.payment?.description}
                      </td>
                      <td>{formatUsd(item.amount, { showZero: true })}</td>
                      {showBalance && (
                        <td>{formatUsd(item.balance, { showZero: true })}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={styles.notable}>
                There were no charges in this period
              </div>
            )}
          </Flex>
        </Flex>

        <Flex className="px-4 pt-8">
          <div>
            <Logo
              to="/"
              color="blue"
              logoClassName="h-8 break-inside-avoid bg-center"
              onLoad={() => setIsLogoLoaded(true)}
            />
          </div>
        </Flex>

        {footer && (
          <div className="px-4 text-xs font-normal">
            <p dangerouslySetInnerHTML={{ __html: footer }}></p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <PageLoader />
  )
}

export { InvestorStatement }
