import { kebabCase } from 'lodash'
import { useEffect, useState } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportTrustTransactions } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { downloadTrustTransactions } from 'admin/services/csv/download-trust-transactions'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { LoadMore } from 'components/LoadMore'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { Select } from 'components/Select'
import { Tabs } from 'components/Tabs'
import { useLoans } from 'hooks/use-loans'
import { usePagination } from 'hooks/use-pagination'
import { Transaction, Trust } from 'types'
import { TableTrustTransactions } from './TableTrustTransactions'

export const TrustTransactionsReport = () => {
  const { data: loans } = useLoans({
    filter: { status: ['servicing'] },
    sort: ['name', 'ASC'],
  })
  const [isFetchingVisible, setIsFetchingVisible] = useState(false)
  const [selectedLoan, setSelectedLoan] = useState<string>()
  const [trusts, setTrusts] = useState<Trust[]>([])
  const [selectedTrustId, setSelectedTrustId] = useState<string>()
  const { visibleItems, result, sort, setSort, setPagination } =
    usePagination<Transaction>({
      property: 'transactions',
      useData: (tableProps) =>
        useReportTrustTransactions(
          {
            loanId: selectedLoan === 'all' ? null : selectedLoan,
            trustId: selectedTrustId,
          },
          tableProps
        ),
    })

  useEffect(() => {
    if (result?.data) {
      const selectedTrustId = result.data?.trust?.id
      const availableTrusts = result.data?.trusts || []
      setTrusts(availableTrusts)
      setSelectedTrustId(selectedTrustId)
    }
  }, [result?.data])

  useEffect(() => {
    setIsFetchingVisible(true)
  }, [selectedTrustId])

  useEffect(() => {
    if (!result.isFetching) {
      setIsFetchingVisible(false)
    }
  }, [result.isFetching])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Trust Transactions"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        />
        <Grid>
          <Grid.Item xs={12} md={6} lg={3}>
            <Flex stack gap={8}>
              <div>Loan</div>
              <Select
                value={selectedLoan}
                options={[
                  { value: 'all', label: '(All Loans)' },
                  ...(loans?.loans.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) || []),
                ]}
                portal
                onChange={(option) => setSelectedLoan(option.value as string)}
              />
            </Flex>
          </Grid.Item>
        </Grid>
        {selectedLoan && result.isPending && <PageLoader />}
        {selectedLoan && !!trusts.length && (
          <Tabs tab={selectedTrustId} onTabSelect={setSelectedTrustId}>
            {trusts.map((trust) => (
              <Tabs.Pane key={trust.id} tab={trust.name} id={trust.id}>
                <Panel
                  title={`${trust.name} transactions`}
                  actionChildren={
                    <Download
                      filename={kebabCase(`${trust.name} transactions`)}
                      download={() =>
                        downloadTrustTransactions({
                          loanId: selectedLoan === 'all' ? null : selectedLoan,
                          trustId: selectedTrustId,
                        })
                      }
                    />
                  }
                >
                  <TableTrustTransactions
                    data={isFetchingVisible ? [] : visibleItems}
                    showLoan={selectedLoan === 'all'}
                    loading={
                      result.isPending ||
                      (isFetchingVisible && result.isFetching)
                    }
                    sort={sort}
                    onSort={setSort}
                  />
                  <LoadMore
                    loading={
                      result.isPending ||
                      (isFetchingVisible && result.isFetching)
                    }
                    fetching={result.isFetching}
                    count={visibleItems.length}
                    meta={result.data?.meta}
                    onLoadMore={setPagination}
                  />
                </Panel>
              </Tabs.Pane>
            ))}
          </Tabs>
        )}
      </Flex>
    </MainContent>
  )
}
