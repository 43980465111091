import { useCallback, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  value: string
  placeholder: string
  onChange: (value: string) => void
}

function NoteField({ value, placeholder, onChange }: Props) {
  const [isExpanded, setIsExpanded] = useState(false)

  const autoGrow = useCallback(({ target }) => {
    target.style.height = `${target.scrollHeight}px`
  }, [])

  return isExpanded ? (
    <Flex
      stack
      gap={12}
      className="rounded border border-solid border-yellow-100 bg-yellow-50 p-4"
    >
      <Flex gap={4} alignItems="center" className="font-bold">
        <Icon name={IconName.note} />
        Note
      </Flex>
      <textarea
        value={value}
        className="max-h-32 w-full resize-none overflow-hidden border-0 bg-yellow-50"
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
        onInput={autoGrow}
      />
    </Flex>
  ) : (
    <Flex
      gap={4}
      alignItems="center"
      onClick={() => setIsExpanded(true)}
      className="cursor-pointer font-bold"
    >
      <Icon name={IconName.addNote} />
      Add note
    </Flex>
  )
}

export { NoteField }
