import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import styles from './styles.module.scss'

interface Props {
  logoUrl: string
  onMenuClick: () => void
}

export const MainHeader = ({ onMenuClick, logoUrl }: Props) => {
  return (
    <Flex gap={16} alignItems="center" className={styles.mainHeader}>
      <Icon
        name={IconName.hamburger}
        className={styles.menuIcon}
        size="xl"
        onClick={onMenuClick}
      />
      <Logo to={logoUrl} logoClassName={styles.logo} />
    </Flex>
  )
}
