import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAddQuote } from 'admin/hooks/use-quotes'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { pathTo } from 'admin/path-to'
import { TabLoanApplication } from 'components/Application/TabLoanApplication'
import { IconName } from 'components/Icon'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { message } from 'utils/message'
import TabActivities from './TabActivities'
import TabAmortization from './TabAmortization'
import TabBorrower from './TabBorrower'
import TabCharges from './TabCharges'
import { TabCollateral } from './TabCollateral'
import TabDocuments from './TabDocuments'
import TabEmailLog from './TabEmailLog'
import TabFunding from './TabFunding'
import { TabGeneralActions } from './TabGeneralActions'
import TabGeneralOriginator from './TabGeneralOriginator'
import TabNotifications from './TabNotifications'
import TabPayments from './TabPayments'
import TabScopeOfWork from './TabScopeOfWork'
import TabSettings from './TabSettings'
import TabVendors from './TabVendors'
import styles from './styles.module.scss'

function LoanViewOriginator() {
  const { user } = useSession()
  const { id, tab } = useParams() as { id: string; tab: string }
  const [action, setAction] = useState<string | null>(null)
  const navigate = useNavigate()
  const { loan, settings } = useLoanContext()
  const showInvestors = user?.client.settings?.hideInvestors !== true

  const { mutate: addQuote } = useAddQuote()

  return (
    <>
      <Tabs
        key={tab}
        defaultActiveId={tab || 'loanTerms'}
        onTabSelect={(tabId) => navigate(pathTo('loanTab', id, tabId))}
        className={styles.tabs}
        actions={[
          {
            label: 'Generate Quote',
            icon: IconName.quote,
            onSelect: () => {
              const hideMessage = message.progress('Generating Quote…', 2000)

              addQuote(
                { loanId: loan.id },
                {
                  onSuccess: (quote) => {
                    hideMessage()
                    navigate(pathTo('quote', 'all', quote.id))
                  },
                }
              )
            },
          },
          {
            label:
              loan.status === 'servicing'
                ? 'Rollback from Servicing'
                : 'Move to Servicing',
            icon: IconName.moveFile,
            onSelect: () => {
              setAction(loan.status === 'servicing' ? 'rollback' : 'servicing')
            },
          },
          {
            label: 'Share with Funding Partner',
            icon: IconName.send,
            disabled:
              !loan.product || loan.product?.clientId === user?.client.id,
            onSelect: () => {
              setAction('share')
            },
          },
          {
            label: loan.status === 'archived' ? 'Restore' : 'Archive',
            icon: IconName.archive,
            onSelect: () => {
              setAction(loan.status === 'archived' ? 'restore' : 'archive')
            },
          },
          {
            label: 'Duplicate',
            icon: IconName.duplicate,
            onSelect: () => {
              setAction('duplicate')
            },
          },
          {
            label: 'Delete',
            variant: 'danger',
            icon: IconName.delete,
            onSelect: () => {
              setAction('delete')
            },
          },
        ]}
      >
        <Tabs.Pane tab="General" id="loanTerms">
          <TabGeneralOriginator loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Documents" id="documents">
          <TabDocuments loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Borrower" id="borrower">
          <TabBorrower loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Charges" id="charges">
          <TabCharges loan={loan} />
        </Tabs.Pane>
        {loan.applicationId ? (
          <Tabs.Pane tab="Application" id="application">
            <TabLoanApplication
              loanId={loan.id}
              applicationId={loan.applicationId}
            />
          </Tabs.Pane>
        ) : null}
        <Tabs.Pane
          tab="Collateral"
          id="collateral"
          className={styles.tabWithNavigation}
        >
          <TabCollateral loan={loan} />
        </Tabs.Pane>
        {settings.showBudget ? (
          <Tabs.Pane
            tab="Scope of Work"
            id="scopeOfWork"
            className={styles.tabWithNavigation}
            disabled={!loan.addresses?.[0]?.id}
            group={loan.hasApplication ? 'More' : undefined}
          >
            <TabScopeOfWork loan={loan} />
          </Tabs.Pane>
        ) : null}
        <Tabs.Pane tab="Amortization Schedule" id="amortization" group="More">
          <TabAmortization loan={loan} />
        </Tabs.Pane>
        {showInvestors ? (
          <>
            <Tabs.Pane tab="Funding" id="funding" group="More">
              <TabFunding loan={loan} />
            </Tabs.Pane>
            <Tabs.Pane tab="Payments" id="payments" group="More">
              <TabPayments loan={loan} />
            </Tabs.Pane>
          </>
        ) : (
          <></>
        )}

        <Tabs.Pane tab="Contacts" id="contacts" group="More">
          <TabVendors loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Change Log" id="changeLog" group="More">
          <TabActivities loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Email Log" id="emailLog" group="More">
          <TabEmailLog loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Notifications" id="notifications" group="More">
          <TabNotifications loan={loan} />
        </Tabs.Pane>
        <Tabs.Pane tab="Settings" id="settings" group="More">
          <TabSettings loan={loan} />
        </Tabs.Pane>
      </Tabs>
      <TabGeneralActions loan={loan} action={action} setAction={setAction} />
    </>
  )
}

export { LoanViewOriginator }
