import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useFieldsByPersonId } from 'admin/hooks/use-person-fields'
import { useReport } from 'admin/hooks/use-report'
import { useVendor, useRemoveVendor } from 'admin/hooks/use-vendors'
import { pathTo } from 'admin/path-to'
import { Vendor as VendorDetails } from 'admin/services/api/vendors'
import { AccountTypeBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTop } from 'components/PageTop'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { formatUsd } from 'utils/currency'
import TabGeneral from './TabGeneral'
import TabTransactions from './TabTransactions'
import styles from './styles.module.scss'

function Vendor() {
  const [vendor, setVendor] = useState<VendorDetails>()
  const { id, tab = 'general' } = useParams() as { id: string; tab: string }
  const { data } = useVendor(id)
  const navigate = useNavigate()
  const { data: cashBalances } = useReport(
    `investor/${id}/cash-balances`
  ) as unknown as {
    data: { cash: string; availableCash: string }
  }
  const { data: fields } = useFieldsByPersonId({
    personType: 'vendor',
    personId: id,
  })
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: deleteVendor } = useRemoveVendor({ exact: true })

  const handleClickDelete = () => {
    if (vendor) {
      deleteVendor(vendor.id, {
        onSuccess: () => navigate(pathTo('vendors')),
      })
    }
  }

  useEffect(() => {
    if (data && fields) {
      setVendor(data)
    }
  }, [data, fields])

  return (
    <MainContent>
      {vendor ? (
        <>
          <PageTop
            title={vendor.name}
            breadcrumbs={{ title: 'Vendors', link: pathTo('vendors') }}
          />
          <div className={styles.badges}>
            <AccountTypeBadge type={vendor.type} isAccount={vendor.isAccount} />
          </div>
          <PageTopFields
            className="mb-6"
            fields={[
              {
                key: 'Cash Balance',
                value: formatUsd(cashBalances?.cash || 0),
              },
            ]}
          />
          <Tabs
            defaultActiveId={tab}
            className="mb-2"
            onTabSelect={(tabId) => navigate(pathTo('vendor', id, tabId))}
            afterTabs={
              <EllipsesActions
                trigger={<Icon name={IconName.ellipses} size="md" />}
              >
                <EllipsesActions.Item
                  icon
                  onSelect={() => setIsModalVisible(true)}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Delete
                </EllipsesActions.Item>
              </EllipsesActions>
            }
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral vendor={vendor} fields={fields} />
            </Tabs.Pane>
            <Tabs.Pane tab="Transactions" id="transactions">
              <TabTransactions vendor={vendor} />
            </Tabs.Pane>
          </Tabs>
          {isModalVisible && (
            <ModalDelete
              resource="vendor"
              name={vendor.name}
              onDelete={handleClickDelete}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Vendor }
