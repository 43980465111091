import { PageLoader } from 'components/LoaderOverlay'
import { TableDocuments } from 'components/TableDocuments'
import { useDownloadDocument } from 'hooks/use-document'
import { LoanDocument } from 'types'

interface Props {
  documents: LoanDocument[] | undefined
  loading: boolean
}

function TabDocuments({ documents, loading }: Props) {
  const { mutate: downloadDocument } = useDownloadDocument()
  const handleDownload = (id: string) => downloadDocument(id)

  return loading ? (
    <PageLoader />
  ) : (
    <TableDocuments
      data={documents?.map((doc) => ({
        ...doc,
        isCustom: false,
      }))}
      hideStatus
      hideEllipsesMenu
      showDownload
      onDownload={handleDownload}
    />
  )
}

export { TabDocuments }
