import { MainContent } from 'borrower/components/layout/MainContent'
import { Flex } from 'components/Flex'
import PageTop from 'components/PageTop/PageTop'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { TabDocuments } from './TabDocuments'
import { TabStatements } from './TabStatements'

function Documents() {
  const { user } = useSession()
  const investorId = user?.actor?.id as string
  const isInvestor = user?.actor?.isInvestor && !user?.actor?.isBorrower

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Documents" hideTopMenu={true} />
        {isInvestor ? (
          <Tabs defaultActiveId="documents">
            <Tabs.Pane id="documents" tab="Documents">
              <TabDocuments investorId={investorId} isInvestor={isInvestor} />
            </Tabs.Pane>
            <Tabs.Pane id="statements" tab="Statements">
              <TabStatements investorId={investorId} />
            </Tabs.Pane>
          </Tabs>
        ) : (
          <TabDocuments investorId={investorId} isInvestor={isInvestor} />
        )}
      </Flex>
    </MainContent>
  )
}

export { Documents }
