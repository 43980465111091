import { useQuery, useMutation } from '@tanstack/react-query'
import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { signUp, validateInvitation } from 'services/api/session'
import { handleErrorResponse } from 'services/request'
import {
  confirmation,
  createScheme,
  email,
  mergeRules,
  passwordMin,
  required,
} from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
}

type FormValues = {
  name: string
  email: string
  password: string
  repeatPassword: string
}

const SignUpSchema = createScheme({
  name: required,
  email: mergeRules(email, required),
  password: mergeRules(passwordMin, required),
  repeatPassword: mergeRules(confirmation('password'), required),
})

function SignUp({ pathTo }: Props) {
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
  })
  const navigate = useNavigate()
  const [isTokenValid, setIsTokenValid] = useState<boolean>()
  const [searchParams] = useSearchParams()

  const {
    data: validationData,
    isPending: isValidatingToken,
    isSuccess: isTokenValidationSuccess,
    isError: isTokenValidationError,
  } = useQuery({
    queryKey: ['validate'],
    queryFn: () =>
      validateInvitation({
        subdomain: subdomain,
        invitation: searchParams.get('token') as string,
      }),
  })
  useEffect(() => {
    if (isTokenValidationSuccess) {
      const { valid, ...formValues } = validationData
      setInitialValues({ ...initialValues, ...formValues })
      if (valid) {
        setIsTokenValid(valid)
      } else {
        navigate(pathTo('signIn'))
      }
    }
  }, [isTokenValidationSuccess])
  useEffect(() => {
    if (isTokenValidationError) {
      setIsTokenValid(false)
    }
  }, [isTokenValidationError])

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: signUp,
    onError: handleErrorResponse,
  })
  const handleSubmit = (data: FormValues) => {
    mutate({
      invitation: searchParams.get('token') as string,
      subdomain: subdomain,
      ...data,
    })
  }

  if (isValidatingToken) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo color="blue" to={pathTo('signIn')} />
        {isTokenValid && isSuccess && (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Account successfully created
            </Header>
            <Text className={styles.description}>Let’s get you started.</Text>
            <Button onClick={() => navigate(pathTo('signIn'))}>
              Log in now
            </Button>
          </div>
        )}
        {isTokenValid && !isSuccess && (
          <Formik
            initialValues={initialValues}
            validationSchema={SignUpSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Form className={styles.form} method="post">
              <Header variant="h1" className={styles.title}>
                Account setup
              </Header>
              <Text className={styles.description}>
                To get started, please verify your name, email address and
                password
              </Text>
              <Grid gap={16}>
                <Grid.Item sm={12}>
                  <Field
                    name="name"
                    label="Name"
                    placeholder="Enter your full name"
                  />
                </Grid.Item>
                <Grid.Item sm={12}>
                  <Field
                    type="email"
                    name="email"
                    label="Email address"
                    placeholder="Enter your email"
                    disabled
                    autoComplete="email"
                  />
                </Grid.Item>
                <Grid.Item sm={12}>
                  <Field
                    type="password"
                    name="password"
                    label="New password"
                    placeholder="Enter your new password"
                    autoComplete="new-password"
                  />
                </Grid.Item>
                <Grid.Item sm={12}>
                  <Field
                    type="password"
                    name="repeatPassword"
                    label="Repeat password"
                    placeholder="Enter your new password again"
                    autoComplete="new-password"
                  />
                </Grid.Item>
              </Grid>
              <Button loading={isPending} type="submit">
                Setup account
              </Button>
            </Form>
          </Formik>
        )}
        <div />
      </div>
    </div>
  )
}

export { SignUp }
