import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { Person } from 'types'
import { formatPhone } from 'utils/phone'

interface Props {
  person: Person
}

const QuotePersonDetails = ({ person }: Props) => (
  <Flex gap={4} stack className="mt-5">
    <Text variant="l" className="font-bold">
      {person.name}
    </Text>
    {person.email && <Text>{person.email}</Text>}
    {person.phone && <Text>{formatPhone(person.phone as string)}</Text>}
  </Flex>
)

export { QuotePersonDetails }
