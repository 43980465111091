import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical'
import { useEffect } from 'react'
import type { LexicalEditor } from 'lexical'

type Props = {
  onFocus: (editor: LexicalEditor) => void
  onBlur?: (editor: LexicalEditor) => void
}

function FocusPlugin({ onFocus, onBlur }: Props): null {
  const [editor] = useLexicalComposerContext()

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur?.(editor)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    []
  )

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus(editor)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    []
  )

  return null
}

export { FocusPlugin }
