import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'borrower/components/layout/MainContent'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import PageTop from 'components/PageTop/PageTop'
import { Tabs } from 'components/Tabs'
import { ActiveTab } from './ActiveTab'
import { PaidOffTab } from './PaidOffTab'
import styles from './styles.module.scss'

function Loans() {
  const { tab } = useParams() as { tab: string }
  const navigate = useNavigate()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Loans" hideTopMenu={true} />
        <Tabs
          defaultActiveId={tab || 'active'}
          onTabSelect={(tabId) =>
            navigate(
              pathTo(tabId === 'active' ? 'loansTabActive' : 'loansTabPaidOff')
            )
          }
        >
          <Tabs.Pane tab="Active Loans" id="active" className={styles.noMargin}>
            <ActiveTab />
          </Tabs.Pane>
          <Tabs.Pane
            tab="Paid Off Loans"
            id="paid-off"
            className={styles.noMargin}
          >
            <PaidOffTab />
          </Tabs.Pane>
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { Loans }
