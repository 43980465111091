import { useCallback } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportInsurance } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { downloadInsurance } from 'admin/services/csv/download-insurance'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Insurance } from 'types'
import TableInsuranceExpiration from './TableInsuranceExpiration'

function InsuranceExpirationReport() {
  const { visibleItems, result, filter, sort, search, setSort, setPagination } =
    usePagination<Insurance>({
      property: 'insurances',
      useData: (tableProps) => useReportInsurance('loan-insurance', tableProps),
    })
  const handleDownload = useCallback(
    () => downloadInsurance({ filter, sort, search }),
    [filter, sort, search]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Insurance Expiration"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download filename="insurances" download={handleDownload} />
        </PageTop>
        <Panel>
          <TableInsuranceExpiration
            data={visibleItems}
            loading={result.isPending}
            sort={sort}
            onSort={setSort}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { InsuranceExpirationReport }
