import { capitalize } from 'lodash'
import { Loan } from 'types'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface StatusBadgeProps {
  status: Loan['status']
}

const loanStatusToColor: Record<
  Loan['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  lead: 'blue',
  processing: 'yellow',
  underwriting: 'purple',
  approved: 'green',
  closed: 'gray',
  archived: 'gray',
  servicing: 'green',
  draft: 'gray',
  liquidated: 'gray',
}

function LoanStatusBadge({ status }: StatusBadgeProps) {
  return <Badge color={loanStatusToColor[status]}>{capitalize(status)}</Badge>
}

export { loanStatusToColor }
export default LoanStatusBadge
