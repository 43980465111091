import { useQuery, useMutation } from '@tanstack/react-query'
import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { setPassword, validateToken } from 'services/api/session'
import { handleErrorResponse } from 'services/request'
import { confirmation, createScheme, mergeRules, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
}

type FormValues = {
  password: string
  repeatPassword: string
}

const SetPasswordSchema = createScheme({
  password: required,
  repeatPassword: mergeRules(confirmation('password'), required),
})

const initialValues: FormValues = {
  password: '',
  repeatPassword: '',
}

function SetPassword({ pathTo }: Props) {
  const navigate = useNavigate()
  const [isTokenValid, setIsTokenValid] = useState<boolean>()
  const [searchParams] = useSearchParams()

  const {
    data: validationData,
    isPending: isValidatingToken,
    isSuccess: isTokenValidationSuccess,
    isError: isTokenValidationError,
  } = useQuery({
    queryKey: ['validate'],
    queryFn: () =>
      validateToken({ token: searchParams.get('token') as string }),
  })
  useEffect(() => {
    if (isTokenValidationSuccess) {
      setIsTokenValid(validationData.valid)
    }
  }, [isTokenValidationSuccess])
  useEffect(() => {
    if (isTokenValidationError) {
      setIsTokenValid(false)
    }
  }, [isTokenValidationError])

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: setPassword,
    onError: handleErrorResponse,
  })
  const handleSubmit = ({ password }: FormValues) => {
    mutate({ password, token: searchParams.get('token') as string })
  }

  if (isValidatingToken) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo color="blue" to={pathTo('signIn')} />
        {!isTokenValid && (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Your password reset link has expired.
            </Header>
            <Text className={styles.description}>
              Hi there, your password reset link has expired because you haven’t
              used it. This link expires after 24 hours and can only be used
              once. You can request a new one by clicking on the button below.
            </Text>
            <Button onClick={() => navigate(pathTo('forgotPassword'))}>
              Request another link
            </Button>
          </div>
        )}
        {isTokenValid && isSuccess && (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Password changed!
            </Header>
            <Text className={styles.description}>
              Your password has been successfully changed.
            </Text>
            <Button onClick={() => navigate(pathTo('signIn'))}>
              Log in now
            </Button>
          </div>
        )}
        {isTokenValid && !isSuccess && (
          <Formik
            initialValues={initialValues}
            validationSchema={SetPasswordSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Form className={styles.form} method="post">
              <Header variant="h1" className={styles.title}>
                Password Reset
              </Header>
              <Text className={styles.description}>
                Enter new password, then repeat it so you don’t forget.
              </Text>
              <Field type="password" name="password" label="New password" />
              <Field
                type="password"
                name="repeatPassword"
                label="Repeat password"
              />
              <Button loading={isPending} type="submit">
                Save
              </Button>
            </Form>
          </Formik>
        )}
        <div />
      </div>
    </div>
  )
}

export { SetPassword }
