import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddFund, useFunds, useDeleteFund } from 'admin/hooks/use-funds'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { IconName, Icon } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalFund } from 'components/Modal/Fund'
import { PageTop } from 'components/PageTop'
import { formatUsd } from 'utils/currency'
import { EmptyFunds } from './EmptyFunds'
import styles from './styles.module.scss'

const Funds = () => {
  const navigate = useNavigate()
  const { data: funds, isPending } = useFunds()
  const { mutate: add, isPending: adding } = useAddFund()
  const { mutate: deleteFund } = useDeleteFund()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<{
    id: string
    name: string
  } | null>(null)

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Funds">
          {!isPending && !!funds?.length && (
            <Button onClick={() => setIsModalVisible(true)}>Add Fund</Button>
          )}
        </PageTop>
        {isPending && <PageLoader />}
        {!isPending && !funds?.length && (
          <EmptyFunds onClick={() => setIsModalVisible(true)} />
        )}
        {!isPending && !!funds?.length && (
          <Grid gap={16}>
            {funds?.map(
              ({ id, name, fundBalance, numInvestments, numInvestors }) => (
                <Grid.Item
                  key={id}
                  xs={12}
                  md={6}
                  xl={4}
                  className={styles.fund}
                  onClick={() => navigate(pathTo('fund', id))}
                >
                  <Flex stack gap={16}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Header variant="h4">{name}</Header>
                      <EllipsesActions>
                        <EllipsesActions.Item
                          icon
                          onSelect={() => setIsDeleteModalVisible({ id, name })}
                          className="text-red-100"
                        >
                          <Icon name={IconName.delete} />
                          Delete
                        </EllipsesActions.Item>
                      </EllipsesActions>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      className={styles.kpis}
                    >
                      <Flex stack gap={4}>
                        <div className={styles.kpiTitle}>Balance</div>
                        <div className={styles.kpiValue}>
                          {formatUsd(fundBalance, { showZero: true })}
                        </div>
                      </Flex>
                      <Flex stack gap={4}>
                        <div className={styles.kpiTitle}>Portfolio</div>
                        <div className={styles.kpiValue}>
                          {numInvestments} investments
                        </div>
                      </Flex>
                      <Flex stack gap={4}>
                        <div className={styles.kpiTitle}>Investors</div>
                        <div className={styles.kpiValue}>
                          {numInvestors} investors
                        </div>
                      </Flex>
                    </Flex>
                  </Flex>
                </Grid.Item>
              )
            )}
          </Grid>
        )}
      </Flex>
      {isModalVisible && (
        <ModalFund
          saving={adding}
          onSave={(person) =>
            add(person, { onSuccess: () => setIsModalVisible(false) })
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      {isDeleteModalVisible && (
        <ModalDelete
          resource="Fund"
          name={isDeleteModalVisible.name}
          onDelete={() =>
            deleteFund(isDeleteModalVisible.id, {
              onSuccess: () => {
                setIsDeleteModalVisible(null)
                navigate(pathTo('funds'))
              },
            })
          }
          onCancel={() => setIsDeleteModalVisible(null)}
        />
      )}
    </MainContent>
  )
}

export { Funds }
