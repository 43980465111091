import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableProducts } from 'admin/components/table/TableProducts'
import {
  useProducts,
  useDuplicateProduct,
  useRemoveProduct,
} from 'admin/hooks/use-products'
import { pathTo } from 'admin/path-to'
import { Product } from 'admin/services/api/products'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import PageTop from 'components/PageTop/PageTop'

function Products() {
  const [removingId, setRemovingId] = useState<string | null>(null)
  const navigate = useNavigate()
  const handleRowClick = (product?: Product) => {
    if (product?.id) {
      navigate(pathTo('product', product.id))
    }
  }
  const { data, isPending } = useProducts()

  const duplicate = useDuplicateProduct()
  const handleDuplicate = (id: string) => duplicate.mutate({ id })
  const remove = useRemoveProduct()

  const products =
    (data?.products || []).map((product) => ({
      ...product,
      onRename: (id: string) => navigate(pathTo('editProduct', id)),
      onDuplicate: (id: string) => handleDuplicate(id),
      onDelete: (id: string) => setRemovingId(id),
    })) || []

  return (
    <MainContent>
      {isPending ? (
        <PageLoader />
      ) : (
        <Flex stack gap={16}>
          <PageTop
            breadcrumbs={{
              title: 'Company Settings',
              link: pathTo('settings'),
            }}
            title="Products"
          >
            <Button
              variant="primary"
              onClick={() => navigate(pathTo('newProduct'))}
            >
              Add Product
            </Button>
          </PageTop>
          <TableProducts data={products} onClick={handleRowClick} />
        </Flex>
      )}

      {removingId && (
        <ModalDelete
          resource="product"
          loading={remove.isPending}
          onDelete={() =>
            remove.mutate(removingId, {
              onSettled: () => {
                setRemovingId(null)
              },
            })
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </MainContent>
  )
}

export { Products }
