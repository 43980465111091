import clsx from 'clsx'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface Props {
  onClick: () => void
  icon: IconName
  label: string
  collapsed?: boolean
}

function SideNavigationSupportItem({ onClick, icon, label, collapsed }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.link, {
        [styles.collapsed]: collapsed,
      })}
    >
      <Icon name={icon} size={collapsed ? 'lg' : 'md'} />
      {collapsed ? '' : label}
    </div>
  )
}

export default SideNavigationSupportItem
