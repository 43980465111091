import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { InlineSummary } from 'admin/components/InlineEdit'
import { useUpdateOffering } from 'admin/hooks/use-offerings'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Indicator } from 'components/Indicator'
import { PageTop } from 'components/PageTop'
import { Summary } from 'components/Summary'
import { Offering } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
}

export const OfferingHeader = ({ offering }: Props) => {
  const { mutateAsync: updateOffering } = useUpdateOffering(offering.id)

  const isFundView = useMemo(
    () => offering.type === 'Fund Offering',
    [offering]
  )

  return (
    <>
      <PageTop
        title={offering.name}
        breadcrumbs={{ title: 'Offerings', link: pathTo('offerings') }}
        onEdit={(name: string) => updateOffering({ name })}
        maxLength={100}
      />
      {isFundView ? (
        <div className="h-6" />
      ) : (
        <Flex alignItems="center" gap={4} className={styles.badges}>
          <Indicator
            color={offering.percentSubscribed === 100 ? 'green' : 'yellow'}
          />
          {offering.percentSubscribed === 100
            ? 'Closed'
            : `${formatPercent(offering.percentSubscribed, { showZero: true })} subscribed`}
        </Flex>
      )}
      <Flex gap={40}>
        <InlineSummary
          type="date"
          name="Offered"
          value={formatDate(offering.dateIssue, 'yyyy-MM-dd')}
          save={(date) => updateOffering({ dateIssue: date as string })}
        />

        {!isFundView && (
          <Summary name="Amount">{formatUsd(offering.amount)}</Summary>
        )}

        {isFundView && (
          <Summary name="Linked Fund">
            <NavLink
              to={pathTo('fund', offering.fundId as string)}
              className="link"
            >
              {offering.fund?.name}
            </NavLink>
          </Summary>
        )}
      </Flex>
    </>
  )
}
