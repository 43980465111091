import { useState, useEffect } from 'react'
import { useInvestor } from 'admin/hooks/use-investors'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'
import { useBorrower } from 'hooks/use-borrower'
import { PersonDetails } from 'types'
import { PanelEmail } from './PanelEmail'
import { PanelPassword } from './PanelPassword'
import { PanelPersonalInformation } from './PanelPersonalInformation'

interface Props {
  actorId: string
  actorType: 'investor' | 'borrower'
}

function SettingsIndividual({ actorId, actorType }: Props) {
  const [person, setPerson] = useState<PersonDetails>()
  const [personSecure, setPersonSecure] = useState<boolean>(false)
  const { data, isPending } = (
    actorType === 'investor' ? useInvestor : useBorrower
  )({
    id: actorId,
    params: personSecure ? { secure: personSecure } : undefined,
  })

  useEffect(() => {
    if (data) {
      setPerson(data)
    }
  }, [data])

  return person ? (
    <Flex stack gap={24}>
      <PageTop
        title={
          <Flex alignItems="center">
            <Avatar
              id={person.id}
              name={person.name}
              className="h-8 min-w-8 flex-shrink-0"
            />
            {person.name}
            <div className="text-grey-500">·</div>
            <div className="text-grey-600">Personal Settings</div>
          </Flex>
        }
        hideTopMenu
      />
      <PanelPersonalInformation
        user={person}
        isLoading={isPending}
        setSecure={() => setPersonSecure(true)}
      />
      <PanelEmail user={person} />
      <PanelPassword />
    </Flex>
  ) : (
    <PageLoader />
  )
}

export { SettingsIndividual }
