import { Formik } from 'formik'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Loan } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  loan: Loan
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalShareLoan({ loan, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal
      title={`Share "${loan.name}"`}
      onClose={onCancel}
      className={styles.modal}
    >
      <Flex stack gap={16}>
        <div>
          Once {loan.product.client.name} accepts your invite they will be able
          to collaborate on this loan with you
        </div>
        <Flex stack gap={8}>
          <Header variant="h4">Share with</Header>
          <Flex gap={8} alignItems="center">
            <Avatar
              id={loan.product.clientId}
              name={loan.product.client.name}
            />
            <div>{loan.product.client.name}</div>
          </Flex>
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValue}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Textarea
                name="message"
                placeholder="Add a message if you’d like"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Share
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalShareLoan
