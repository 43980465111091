import clsx from 'clsx'
import { ErrorMessage, Field, Formik } from 'formik'
import { isString, toNumber, toString } from 'lodash'
import { useState } from 'react'
import { SelectVendor } from 'admin/components/SelectVendor'
import {
  useAddLoanCharge,
  useUpdateLoanCharge,
} from 'admin/hooks/use-loan-charge'
import { useAddVendor } from 'admin/hooks/use-vendors'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor'
import { Vendor } from 'admin/services/api/vendors'
import { Alert } from 'components/Alert'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { FieldIcon, Form } from 'components/Form'
import DateField from 'components/Form/Date'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { TextLink } from 'components/TextLink'
import { Charge } from 'types/charge'
import { formatDate } from 'utils/date'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  charge?: Charge
  onCancel: () => void
}

type FormValues = {
  personId: string
  name: string
  dateCharged: string
  dateDue?: string | null
  amount: string
}

const Schema = createScheme({
  personId: required,
  name: required,
  dateCharged: required,
  amount: required,
})

function AddCharge({ loanId, charge, onCancel }: Props) {
  const [addingVendorName, setAddingVendorName] = useState<string | false>(
    false
  )
  const { mutate: addCharge, isPending: isAdding } = useAddLoanCharge(loanId)
  const { mutate: updateCharge, isPending: isUpdating } = useUpdateLoanCharge({
    loanId,
    chargeId: charge?.id as string,
  })
  const { mutate: addVendor, isPending: isVendorAdding } = useAddVendor()
  const initialValue: FormValues = {
    personId: charge?.personId || '',
    name: charge?.name || '',
    dateCharged: charge?.dateCharged
      ? formatDate(charge.dateCharged, 'yyyy-MM-dd')
      : '',
    dateDue: charge?.dateDue ? formatDate(charge.dateDue, 'yyyy-MM-dd') : null,
    amount: toString(charge?.amount) || '',
  }
  const onSubmit = (values: FormValues) => {
    const saveMethod = charge ? updateCharge : addCharge
    saveMethod(
      {
        ...values,
        dateDue: values.dateDue || null,
        amount: toNumber(values.amount),
      },
      { onSuccess: () => onCancel() }
    )
  }

  return (
    <Modal
      title={charge ? 'Edit Charge' : 'Add Charge'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <div className={formStyles.field}>
                <Field name="personId">
                  {({ meta: { touched, error }, form, field }) => (
                    <>
                      <label
                        htmlFor="personId"
                        className={clsx(formStyles.label, {
                          [formStyles.errorLabel]: touched && error,
                        })}
                      >
                        Payable To
                      </label>
                      <SelectVendor
                        value={field.value}
                        loanId={loanId}
                        className={clsx({
                          [formStyles.errorField]: touched && error,
                        })}
                        onSelect={(personId) =>
                          form.setFieldValue('personId', personId)
                        }
                        onCreate={(text) => setAddingVendorName(text)}
                      />
                      <ErrorMessage
                        component="div"
                        name="personId"
                        className={formStyles.errorMessage}
                      />
                      <Flex gap={4}>
                        <TextLink onClick={() => setAddingVendorName('')}>
                          <Icon name={IconName.plus} size="sm" /> Create a new
                          vendor
                        </TextLink>
                      </Flex>

                      {charge &&
                        charge.amountDue < charge.amount &&
                        field.value !== initialValue.personId && (
                          <div className="pt-4">
                            <Alert severity="warning">
                              Changing the recipient will reassign all existing
                              payments against this charge to the new recipient.
                            </Alert>
                          </div>
                        )}

                      {isString(addingVendorName) && (
                        <ModalAddVendor
                          saving={isVendorAdding}
                          onSave={(vendor) =>
                            addVendor(vendor as Omit<Vendor, 'id'>, {
                              onSuccess: ({ id }) => {
                                form.setFieldValue('personId', id)
                                setAddingVendorName(false)
                              },
                            })
                          }
                          onCancel={() => {
                            setAddingVendorName(false)
                          }}
                        />
                      )}
                    </>
                  )}
                </Field>
              </div>
            </Grid.Item>
            <Grid.Item xs={6}>
              <DateField label="Date" name="dateCharged" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <DateField
                label="Due"
                name="dateDue"
                hint="No due date will hold the charge until payoff"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon label="Description" type="text" name="name" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon label="Amount" type="currency" name="amount" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={isAdding || isUpdating}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default AddCharge
