import { useFormikContext } from 'formik'
import { compact, get, isUndefined } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalAddMember } from 'borrower/components/Modal/AddMember'
import { AccountTypeBadge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Select, Option } from 'components/Form'
import { Icon, IconName } from 'components/Icon'
import { CreateOption } from 'components/Select/CreateOption'
import { TextLink } from 'components/TextLink'
import { useAddMember, useMembers } from 'hooks/use-members'
import { useSession } from 'hooks/use-session'
import { ApplicationSchemeField, PersonType } from 'types'
import { ApplicationField } from './ApplicationField'
import { PersonFormType } from './PersonFormType'

interface Props {
  prefix: string
  field: ApplicationSchemeField
  className?: string
  disabled?: boolean
  onDelete?: () => void
}

const BorrowerForm = ({
  prefix,
  field,
  className,
  disabled,
  onDelete,
}: Props) => {
  const { user } = useSession()
  const [isAddVisible, setIsAddVisible] = useState(false)
  const form = useFormikContext()
  const type = get(form.values, `${prefix}.type`)
  const actorId = user?.actor?.id as string
  const selectedId = get(form.values, `${prefix}.person_id`)
  const selectedPersons = compact(
    (form.values as any)[field.id].map(({ person_id }) => person_id)
  )
  const isPrimaryBorrower =
    field.type === 'borrower' && prefix.split('.')[1] === '0'
  const primaryBorrowerType = get(form.values, `borrower.0.type`)

  const { data } = useMembers(
    {
      managerId: actorId,
      type: 'borrower',
    },
    { enabled: !!actorId }
  )
  const { mutate: add, isPending: isAdding } = useAddMember({
    managerId: actorId,
    type: 'borrower',
  })

  const availablePersons = useMemo(
    () => compact([user?.actor, ...(data?.borrower?.managers || [])]),
    [user?.actor, data]
  )
  const options = useMemo(
    () =>
      availablePersons
        .filter(
          ({ id, type: personType }) =>
            personType === type &&
            (!selectedPersons.includes(id) || id === selectedId)
        )
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    [availablePersons, selectedPersons, selectedId, type]
  )
  const handleSelectPerson = useCallback(
    (option: Option) => {
      const emailFields = field.individualFields?.filter(
        ({ mapTo }) => mapTo === 'borrower-individual-email'
      )
      const phoneFields = field.individualFields?.filter(
        ({ mapTo }) => mapTo === 'borrower-individual-phone'
      )

      const person = availablePersons.find(({ id }) => id === option.value)
      form.setFieldValue(`${prefix}.type`, 'individual')
      form.setFieldValue(`${prefix}.person_id`, person?.id ?? '')
      form.setFieldValue(`${prefix}.name`, person?.name ?? '')
      form.setFieldValue(`${prefix}.credit_score`, person?.creditScore ?? '')
      form.setFieldValue(`${prefix}.citizenship`, person?.citizenship ?? '')
      form.setFieldValue(`${prefix}.num_flipped`, person?.numFlipped ?? '')
      phoneFields?.forEach((field) => {
        form.setFieldValue(`${prefix}.${field.id}`, person?.phone ?? '')
      })
      emailFields?.forEach((field) => {
        form.setFieldValue(`${prefix}.${field.id}`, person?.email ?? '')
      })
    },
    [form, availablePersons]
  )

  // fix to make formik validation work
  useEffect(() => {
    const formValues = get(form.values, prefix)
    const fields =
      formValues.type === 'individual'
        ? field.individualFields
        : field.entityFields
    fields?.forEach((subField) => {
      if (isUndefined(formValues[subField.id])) {
        form.setFieldValue(`${prefix}.${subField.id}`, '')
      }
    })
  }, [form.setFieldValue, prefix, form.values, type])

  return (
    <div className={className}>
      {!type ? (
        <PersonFormType prefix={prefix} />
      ) : (
        <Flex
          stack
          className="mb-6 border-0 border-b border-solid border-grey-100"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <AccountTypeBadge type={type as PersonType} />
              {!isPrimaryBorrower && !disabled && (
                <TextLink
                  onClick={() => form.setFieldValue(prefix, { type: '' })}
                >
                  Change type
                </TextLink>
              )}
            </Flex>
            {onDelete && !isPrimaryBorrower && !disabled && (
              <Button size="small" color="negative" onClick={() => onDelete()}>
                Delete
              </Button>
            )}
          </Flex>
          <div>
            {type === 'individual'
              ? field.individualFields
                  ?.filter(({ enabled }) => enabled)
                  .map((field) => {
                    const isNameDropdownVisible =
                      primaryBorrowerType === 'entity'
                    const isDisabled =
                      disabled ||
                      (field.id === 'name' && isPrimaryBorrower) ||
                      (field.id === 'email' &&
                        !!get(form.values, `${prefix}.email`) &&
                        (isNameDropdownVisible || isPrimaryBorrower))

                    return field.id === 'name' && isNameDropdownVisible ? (
                      <Select
                        key={field.id}
                        name={`${prefix}.person_id`}
                        label={field.label}
                        disabled={isDisabled}
                        options={options}
                        onChange={(value) => handleSelectPerson(value)}
                        createOption={
                          <CreateOption onClick={() => setIsAddVisible(true)}>
                            <Flex
                              gap={4}
                              alignItems="center"
                              className="py-2 font-bold text-blue-200 hover:text-black-100"
                            >
                              <Icon name={IconName.plus} size="sm" />
                              Create a new Borrower
                            </Flex>
                          </CreateOption>
                        }
                      />
                    ) : (
                      <ApplicationField
                        key={field.id}
                        prefix={prefix}
                        disabled={isDisabled}
                        field={field}
                      />
                    )
                  })
              : field.entityFields
                  ?.filter(({ enabled }) => enabled)
                  .map((field) =>
                    field.id === 'name' &&
                    primaryBorrowerType === 'entity' &&
                    isPrimaryBorrower ? (
                      <Select
                        key={field.id}
                        name={`${prefix}.person_id`}
                        label={field.label}
                        disabled={disabled || isPrimaryBorrower}
                        options={options}
                      />
                    ) : (
                      <ApplicationField
                        key={field.id}
                        prefix={prefix}
                        disabled={disabled}
                        field={field}
                      />
                    )
                  )}
          </div>
        </Flex>
      )}

      {isAddVisible && (
        <ModalAddMember
          saving={isAdding}
          onSubmit={(newMember) =>
            add(newMember, {
              onSuccess: (data) => {
                const managers = data?.borrower?.managers || []
                const newManager = managers.find(
                  ({ email }) => email === newMember.email
                )
                form.setFieldValue(`${prefix}.person_id`, newManager?.id)
                form.setFieldValue(`${prefix}.name`, newManager?.name)
                form.setFieldValue(`${prefix}.email`, newManager?.email)
                setIsAddVisible(false)
              },
            })
          }
          onCancel={() => setIsAddVisible(false)}
        />
      )}
    </div>
  )
}

export { BorrowerForm }
