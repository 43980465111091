export type LoanStatus =
  | 'lead'
  | 'processing'
  | 'underwriting'
  | 'approved'
  | 'closed'
  | 'servicing'
  | 'archived'
  | 'draft'
  | 'liquidated'

export const loanStatusOptions = [
  { value: 'lead', label: 'Lead' },
  { value: 'processing', label: 'Processing' },
  { value: 'underwriting', label: 'Underwriting' },
  { value: 'approved', label: 'Approved' },
  { value: 'closed', label: 'Closed' },
]
