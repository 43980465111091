import { omitBy, size } from 'lodash'
import { useEffect } from 'react'
import {
  useTransactions,
  useTransactionFilters,
} from 'admin/hooks/use-transactions'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Transaction } from 'types'
import TableTransactions from './TableTransactions'

const TransactionsReport = () => {
  const { visibleItems, result, filter, setFilter, setPagination } =
    usePagination<Transaction>({
      property: 'transactions',
      useData: useTransactions,
    })
  const { data: availableFilters } = useTransactionFilters([
    'funding_source_class',
    'loan.id',
    'loan.name',
    'person.name',
    'payment.type',
    'charge.name',
    'payment.method',
    'trust.name',
  ])

  useEffect(() => {}, [result.data?.meta?.page])

  return (
    <>
      <Flex stack gap={16}>
        <PageTop title="Transaction History" />
        <Panel>
          <TableTransactions
            data={visibleItems}
            loading={result.isPending}
            availableFilters={availableFilters}
            filter={filter}
            onFilter={(newFilter) => {
              setFilter(
                omitBy({ ...filter, ...newFilter }, (value) => !size(value))
              )
            }}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length || 0}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </>
  )
}

export { TransactionsReport }
