import clsx from 'clsx'
import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import { useMobile } from 'hooks/use-mobile'
import styles from './styles.module.scss'

interface Props {
  collapsed?: boolean
  children: ReactNode
  pathTo: any
  setIsMobileNavVisible: (visible: boolean) => void
}

const SideNavigation = ({
  collapsed,
  children,
  pathTo,
  setIsMobileNavVisible,
}: Props) => {
  const { isTablet } = useMobile()

  return (
    <div
      className={clsx('no-print', styles.nav, {
        [styles.collapsed]: collapsed,
        [styles.mobile]: isTablet,
      })}
    >
      <div className={styles.navContent}>
        <Flex
          alignItems="center"
          justifyContent={collapsed ? 'center' : 'space-between'}
        >
          <Logo
            icon={collapsed}
            wrapperClassName={collapsed ? 'mx-1 my-4' : 'm-4'}
            logoClassName={clsx(styles.logo, {
              [styles.collapsed]: collapsed,
              [styles.mobile]: isTablet,
            })}
            to={pathTo('dashboard')}
          />
          {isTablet && (
            <Icon
              name={IconName.close}
              className={styles.closeIcon}
              size="lg"
              onClick={() => setIsMobileNavVisible(false)}
            />
          )}
        </Flex>
        <div className={styles.menus}>{children}</div>
      </div>
    </div>
  )
}

export default SideNavigation
