import { request } from 'services/request'
import { Person } from 'types'

interface Props {
  search?: string
}

const getPersons = async ({ search }: Props): Promise<Person[]> => {
  const {
    data: { people },
  } = await request.get('/person', { params: { search, details: true } })
  return people
}

export { getPersons }
