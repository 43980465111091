import { isString } from 'lodash'
import ReactSelect from 'react-select'
import DatePicker from 'components/DatePicker/DatePicker'
import { Option } from 'components/Form'
import { IconInput } from 'components/IconInput'
import { InputPhone } from 'components/InputPhone'
import { Field } from 'types/field'

type Props = {
  field: Field
  onChange: (value: string) => void
}

function InputField({ field, onChange }: Props) {
  const {
    type,
    property: { value },
    options,
  } = field
  const indexValue = value[0]
  const indexType = type[0]
  switch (indexType) {
    case 'option':
    case 'yes-no': {
      const selectOptions: Option[] =
        indexType === 'yes-no'
          ? [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]
          : (options as unknown as Option[])
      return (
        <ReactSelect
          classNamePrefix="formFieldSelect"
          placeholder="Select..."
          menuPortalTarget={document.getElementById('select-portal')}
          options={[
            { label: '(blank)', value: '' },
            ...((selectOptions as any[]) || []),
          ]}
          value={
            (selectOptions as any[])?.find(
              (option) => option.value === indexValue
            ) || {
              label: '(blank)',
              value: '',
            }
          }
          onChange={(option) => {
            const value = option && (isString(option) ? option : option.value)
            onChange(value || '')
          }}
        />
      )
    }
    case 'date':
      return <DatePicker value={indexValue as string} onChange={onChange} />
    case 'phone':
      return (
        <InputPhone
          value={indexValue || ''}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      )
    case 'document':
      return <div />
    default:
      return (
        <IconInput
          type={indexType}
          value={indexValue || ''}
          onChange={(e) => onChange(e.target.value)}
        />
      )
  }
}

export { InputField }
