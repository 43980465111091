import { Formik } from 'formik'
import { useState } from 'react'
import { useUpdateCurrentUser } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import styles from 'components/Modal/AddEmail/styles.module.scss'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import {
  confirmation,
  createScheme,
  mergeRules,
  passwordMin,
  required,
} from 'utils/schemas'

function ModalEditPassword({
  onClose,
  onSubmit,
  saving,
}: {
  onClose: () => void
  onSubmit: ({
    password,
    oldPassword,
  }: {
    password: string
    oldPassword: string
  }) => void
  saving: boolean
}) {
  const Schema = createScheme({
    oldPassword: mergeRules(passwordMin, required),
    password: mergeRules(passwordMin, required),
    repeatPassword: mergeRules(confirmation('password'), required),
  })

  return (
    <Modal title="Edit Password" onClose={onClose}>
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          repeatPassword: '',
        }}
        validationSchema={Schema}
        onSubmit={({ password, oldPassword }) =>
          onSubmit({ password, oldPassword })
        }
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="password"
                name="oldPassword"
                label="Current password"
                placeholder="Enter your current password"
                autoComplete="current-password"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                type="password"
                name="password"
                label="New password"
                placeholder="Enter your new password"
                autoComplete="new-password"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                type="password"
                name="repeatPassword"
                label="Repeat password"
                placeholder="Enter your new password again"
                autoComplete="new-password"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

function PanelPassword() {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateCurrentUser()

  return (
    <>
      <Panel title="My Password" onEdit={() => setIsPasswordModalVisible(true)}>
        <Summary name="Password">********</Summary>
      </Panel>
      {isPasswordModalVisible && (
        <ModalEditPassword
          saving={isUpdating}
          onClose={() => setIsPasswordModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsPasswordModalVisible(false) })
          }
        />
      )}
    </>
  )
}

export { PanelPassword }
