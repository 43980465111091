import { useState } from 'react'
import { Outlet, useLocation, matchPath } from 'react-router-dom'
import { routerPath, pathTo } from 'admin/path-to'
import { MainLayout } from 'components/layout'
import { SideNavigation } from '../SideNavigation'

function AdminMainLayout() {
  const location = useLocation()
  const navCollapsed = [
    routerPath.loanDocument,
    routerPath.servicingLoanDocument,
    routerPath.offeringDocument,
    routerPath.personDocument,
  ].some((path) => !!matchPath(path, location.pathname))
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  return (
    <MainLayout
      navigation={
        <SideNavigation
          collapsed={navCollapsed && !isMobileNavVisible}
          setIsMobileNavVisible={setIsMobileNavVisible}
        />
      }
      logoUrl={pathTo('dashboard')}
      isMobileNavVisible={isMobileNavVisible}
      setIsMobileNavVisible={setIsMobileNavVisible}
    >
      <Outlet />
    </MainLayout>
  )
}

export default AdminMainLayout
