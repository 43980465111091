import { useState } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateCurrentUser } from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { ModalEditPassword } from 'components/Modal/EditPassword'
import { ModalEditPersonalInfo } from 'components/Modal/EditPersonalInfo'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { useSession } from 'hooks/use-session'
import { formatPhone } from 'utils/phone'

function MyInfo() {
  const { user } = useSession()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateCurrentUser()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="My Info"
        />

        <Panel
          title="Personal Information"
          onEdit={() => setIsEditModalVisible(true)}
          className="max-w-panel"
        >
          <Grid gap={16}>
            <Grid.Item xs={12}>
              <Summary name="Name">{user?.admin?.name}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Email">{user?.admin?.email}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Phone number">
                {formatPhone(user?.admin?.phone)}
              </Summary>
            </Grid.Item>
          </Grid>
        </Panel>
        <Panel
          title="Security"
          onEdit={() => setIsPasswordModalVisible(true)}
          className="max-w-panel"
        >
          <Summary name="Password">********</Summary>
        </Panel>
      </Flex>
      {isEditModalVisible && (
        <ModalEditPersonalInfo
          initialValues={{
            email: user?.admin?.email as string,
            name: user?.admin?.name as string,
            phone: user?.admin?.phone || '',
          }}
          saving={isUpdating}
          onClose={() => setIsEditModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsEditModalVisible(false) })
          }
        />
      )}
      {isPasswordModalVisible && (
        <ModalEditPassword
          saving={isUpdating}
          onClose={() => setIsPasswordModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsPasswordModalVisible(false) })
          }
        />
      )}
    </MainContent>
  )
}

export { MyInfo }
