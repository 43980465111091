import { Icon, IconName } from '../Icon'
import Portal from '../Modal/Portal'
import styles from '../Modal/styles.module.scss'

function LoaderOverlay() {
  return (
    <Portal className={styles.portal}>
      <div className={styles.overlay} />
      <div className={styles.loader}>
        <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
      </div>
    </Portal>
  )
}

export default LoaderOverlay
