import { ColumnDef, CellContext } from '@tanstack/react-table'
import { isString } from 'lodash'
import { useMemo } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { pathTo } from 'admin/path-to'
import { Borrower } from 'admin/services/api/borrowers'
import { BorrowerStatusBadge } from 'components/Badge'
import { LinkEmail } from 'components/LinkEmail'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Table } from 'components/Table'
import { useUpdateBorrowerOwners } from 'hooks/use-borrower'
import { PersonStatus, Sort } from 'types'
import { formatPhone } from 'utils/phone'

function TablePeople({
  data = [],
  loading,
  sort,
  onSort,
  onClick,
  onUpdateItem,
}: {
  data?: Borrower[]
  loading: boolean
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  onClick: (borrower?: Borrower) => void
  onUpdateItem: (borrower: Borrower) => void
}) {
  const { options } = useOwnerSelector()
  const { mutate: updateOwners } = useUpdateBorrowerOwners()

  const columns: ColumnDef<Borrower>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Associated Companies',
        accessorKey: 'associatedWith',
        size: 204,
        cell: ({ row }) => {
          return (
            <AssociatedBadges
              associatedWith={row.original.associatedWith}
              getUrlById={(id: string) => pathTo('borrower', id)}
            />
          )
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: ({ getValue }) => {
          const email = getValue()
          return isString(email) ? LinkEmail({ email }) : '-'
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        size: 150,
        cell: ({ getValue }) => {
          const phone = getValue()
          return isString(phone) ? formatPhone(phone) : '-'
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Account Owner',
        accessorKey: 'owners',
        size: 120,
        cell: (cellContext) => {
          const { row, hover, active, onActive } = cellContext as CellContext<
            Borrower,
            unknown
          > & {
            hover: boolean
            active: boolean
            onActive: (active: boolean) => void
          }
          const selectedOwners = row.original.owners || []
          const missedOptions =
            selectedOwners?.filter(({ id }) => {
              return !options.find((option) => option.id === id)
            }) || []

          return (
            <OwnerSelector
              variant="small"
              readOnly={!hover && !active}
              selectedUsers={row.original.owners?.map(({ id }) => id) || []}
              userOptions={[
                ...options,
                ...missedOptions.map((option) => ({ ...option, email: '-' })),
              ]}
              onChange={(owners) => {
                updateOwners(
                  { id: row.original.id, owners },
                  { onSuccess: onUpdateItem }
                )
              }}
              onOpen={onActive}
            />
          )
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 90,
        cell: (cell) => {
          const status = cell.getValue() as PersonStatus
          return <BorrowerStatusBadge status={status} />
        },
      },
    ],
    [options]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      sort={sort}
      onSort={onSort}
    />
  )
}

export { TablePeople }
