import { get, set } from 'lodash'
import { InlineSummary } from 'admin/components/InlineEdit'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'

export const PanelGeneralSettings = () => {
  const { user } = useSession()
  const { mutateAsync: update } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    duePaymentsDefaultAddDays: '',
    duePaymentsReminderEmailDays: '',
    generateInvestorAgreements: 'No',
    budgetOveragePercentage: 0,
    autoInvestorAccounting: 'No',
    notifyExpiredAdvanceDays: 0,
  })

  return (
    <Panel title="Settings" className="max-w-panel">
      <Grid gap={24}>
        <Grid.Item xs={12}>
          <InlineSummary
            type="number"
            mask="# days"
            name="How many days before a due date to display payments?"
            value={settings.duePaymentsDefaultAddDays}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                duePaymentsDefaultAddDays: value as number,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineSummary
            type="number"
            mask="# days"
            name="How many days before a due date to send due payment notifications?"
            value={settings.duePaymentsReminderEmailDays}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                duePaymentsReminderEmailDays: value as number,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineSummary
            type="number"
            mask="# days"
            name="How many days before insurance expires do you want to be notified?"
            value={settings.notifyExpiredAdvanceDays}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                notifyExpiredAdvanceDays: value as number,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineSummary
            type="option"
            name="Generate Investor Agreements automatically?"
            value={settings.generateInvestorAgreements || 'No'}
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' },
            ]}
            hideEmptyOption
            save={(value) => {
              const updatedSettings = {
                ...settings,
                generateInvestorAgreements: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineSummary
            type="option"
            name="Investor Trust Accounting"
            value={settings.autoInvestorAccounting || 'No'}
            options={[
              { value: 'No', label: 'Active' },
              { value: 'Yes', label: 'Disabled' },
            ]}
            format={(value) => (value === 'No' ? 'Active' : 'Disabled')}
            hideEmptyOption
            save={(value) => {
              const updatedSettings = {
                ...settings,
                autoInvestorAccounting: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineSummary
            type="percentage"
            name="How much over the budget will you allow draw requests?"
            value={(settings.budgetOveragePercentage || 0) * 100}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                budgetOveragePercentage: (value as number) / 100,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
      </Grid>
    </Panel>
  )
}
