import styles from 'admin/pages/Borrower/styles.module.scss'
import { Borrower } from 'admin/services/api/borrowers'
import { Investor } from 'admin/services/api/investors'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { formatPhone } from 'utils/phone'

interface Props {
  person: Borrower | Investor
  onEdit: () => void
  onSecure?: (secure: boolean) => void
}

function PanelEntityInformation({ person, onEdit, onSecure }: Props) {
  return (
    <Panel title="Information" onEdit={onEdit}>
      <Grid gap={24}>
        <Grid.Item sm={6}>
          <Summary name="Name">{person.name}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Type">{person.entityType}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Jurisdiction">{person.jurisdiction}</Summary>
        </Grid.Item>
        {onSecure && (
          <Grid.Item sm={6}>
            <Summary name="Tax ID">
              {person.isSsnSet && person.socialSecurityNumber}
              {person.isSsnSet && !person.socialSecurityNumber ? (
                <span className={styles.ssnLink} onClick={() => onSecure(true)}>
                  ********* <Icon name={IconName.view} size="md" />
                </span>
              ) : undefined}
            </Summary>
          </Grid.Item>
        )}
        <Grid.Item sm={6}>
          <Summary name="Phone">{formatPhone(person.phone)}</Summary>
        </Grid.Item>
      </Grid>
    </Panel>
  )
}

export default PanelEntityInformation
