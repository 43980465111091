import { useCallback } from 'react'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportTrustBalance } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { downloadTrustBalance } from 'admin/services/csv/download-trust-balance'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'
import TableTrustBalance from './TableTrustBalance'

function TrustBalanceReport() {
  const { visibleItems, result, filter, sort, search, setSort, setPagination } =
    usePagination<Loan>({
      property: 'loans',
      useData: (tableProps) =>
        useReportTrustBalance('loan-trust-balances', tableProps),
    })
  const handleDownload = useCallback(
    () => downloadTrustBalance({ filter, sort, search }),
    [filter, sort, search]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Trust Balance"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download filename="trust-balances" download={handleDownload} />
        </PageTop>
        <Panel>
          <TableTrustBalance
            data={visibleItems}
            trusts={result.data?.trusts}
            loading={result.isPending}
            sort={sort}
            onSort={setSort}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { TrustBalanceReport }
