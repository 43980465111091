import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useInvestor } from 'admin/hooks/use-investors'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'
import { Tabs } from 'components/Tabs'
import { useBorrower } from 'hooks/use-borrower'
import { useSession } from 'hooks/use-session'
import { PersonDetails } from 'types'
import { PanelEmail } from './PanelEmail'
import { PanelEntityInformation } from './PanelEntityInformation'
import { PanelMembers } from './PanelMembers'
import { PanelPassword } from './PanelPassword'
import { PanelPersonalInformation } from './PanelPersonalInformation'

interface Props {
  personId: string
  actorId: string
  actorType: 'investor' | 'borrower'
  personType: 'investor' | 'borrower'
}

function SettingsEntity({ actorId, actorType, personId, personType }: Props) {
  const navigate = useNavigate()
  const { isManager } = useSession()
  const { tab = 'info' } = useParams() as {
    tab: 'info' | 'profile' | 'members'
  }
  const [actor, setActor] = useState<PersonDetails>()
  const [person, setPerson] = useState<PersonDetails>()
  const [actorSecure, setActorSecure] = useState<boolean>(false)
  const [personSecure, setPersonSecure] = useState<boolean>(false)
  const usePerson = actorType === 'investor' ? useInvestor : useBorrower
  const { data, isPending: actorPending } = usePerson({
    id: actorId,
    params: actorSecure ? { secure: actorSecure } : undefined,
  })
  const { data: user, isPending: personPending } = (
    personType === 'investor' ? useInvestor : useBorrower
  )({
    id: personId,
    params: personSecure ? { secure: personSecure } : undefined,
  })
  const isLoading = !actor || !person

  useEffect(() => {
    if (data) {
      setActor(data)
    }
  }, [data])

  useEffect(() => {
    if (user) {
      setPerson(user)
    }
  }, [user])

  return isLoading ? (
    <PageLoader />
  ) : (
    <Flex stack gap={32}>
      <PageTop
        title={
          <Flex alignItems="center">
            {actor.name}
            <div className="text-grey-500">·</div>
            <div className="text-grey-600">Account Settings</div>
          </Flex>
        }
        hideTopMenu
      />
      <Tabs
        defaultActiveId={tab || 'info'}
        onTabSelect={(tab) => navigate(pathTo('settings', tab))}
      >
        <Tabs.Pane tab="Company Info" id="info">
          <PanelEntityInformation
            entity={actor}
            isLoading={actorPending}
            setSecure={() => setActorSecure(true)}
          />
        </Tabs.Pane>
        <Tabs.Pane tab="My Profile & Password" id="profile">
          <Flex stack gap={24}>
            <PanelPersonalInformation
              user={person}
              isLoading={personPending}
              setSecure={() => setPersonSecure(true)}
            />
            <PanelEmail user={person} />
            <PanelPassword />
          </Flex>
        </Tabs.Pane>
        {isManager && (
          <Tabs.Pane tab="Members" id="members">
            <PanelMembers />
          </Tabs.Pane>
        )}
      </Tabs>
    </Flex>
  )
}

export { SettingsEntity }
