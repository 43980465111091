import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getPersons } from 'admin/services/api/persons'
import { KEY_PERSONS } from 'constants/query-keys'

const usePersons = ({ search }: { search?: string } = {}) => {
  return useQuery({
    queryKey: [KEY_PERSONS, search],
    queryFn: () => getPersons({ search }),
    placeholderData: keepPreviousData,
  })
}

export { usePersons }
