import { capitalize } from 'lodash'
import styles from 'admin/pages/Borrower/styles.module.scss'
import { Borrower } from 'admin/services/api/borrowers'
import { Investor } from 'admin/services/api/investors'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { formatDate } from 'utils/date'
import { formatPhone } from 'utils/phone'

interface Props {
  person: Borrower | Investor
  onEdit: () => void
  onSecure: (secure: boolean) => void
  onResetPassword?: ({ id }: { id: string }) => void
  isPasswordResetting?: boolean
  personType: 'borrower' | 'investor'
}

function PanelPersonalInformation({
  person,
  onEdit,
  onSecure,
  onResetPassword,
  isPasswordResetting,
  personType,
}: Props) {
  return (
    <Panel title="Information" onEdit={onEdit}>
      <Grid gap={24}>
        <Grid.Item sm={6}>
          <Summary name="Name">{person.name}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Gender">{capitalize(person.gender || '')}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Date of Birth">
            {person.dateBirth && formatDate(person.dateBirth)}
          </Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Social Security Number">
            {person.isSsnSet && person.socialSecurityNumber}
            {person.isSsnSet && !person.socialSecurityNumber ? (
              <span className={styles.ssnLink} onClick={() => onSecure(true)}>
                ********* <Icon name={IconName.view} size="md" />
              </span>
            ) : undefined}
          </Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Citizenship">{person.citizenship}</Summary>
        </Grid.Item>
        {personType === 'borrower' && (
          <Grid.Item sm={6}>
            <Summary name="Credit Score">{person.creditScore}</Summary>
          </Grid.Item>
        )}
        <Grid.Item sm={6}>
          <Summary name="Marital Status">
            {capitalize(person.maritalStatus || '')}
          </Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Phone">{formatPhone(person.phone)}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Account type">
            {person.isAccount ? 'Full account' : 'Profile'}
          </Summary>
        </Grid.Item>
        {onResetPassword && (
          <Grid.Item sm={6}>
            <Summary name="Password">
              {!['draft', 'invited'].includes(person.status) && (
                <div className={styles.resetPassword}>
                  <div>***********</div>
                  <TextLink onClick={() => onResetPassword({ id: person.id })}>
                    {isPasswordResetting ? 'Resetting' : 'Reset'}
                  </TextLink>
                </div>
              )}
            </Summary>
          </Grid.Item>
        )}
      </Grid>
    </Panel>
  )
}

export default PanelPersonalInformation
