import clsx from 'clsx'
import { isNil, toString } from 'lodash'
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useState,
  useRef,
  useEffect,
} from 'react'
import { IMaskInput } from 'react-imask'
import { mergeRefs } from 'react-merge-refs'
import styles from '../Form/styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

function round(value: string | number | readonly string[] | undefined) {
  if (isNil(value) || isNaN(parseFloat(toString(value)))) {
    return ''
  }
  return Math.round(parseFloat(toString(value)) * 100) / 100
}

const InputCurrency = forwardRef(
  (
    {
      className,
      value,
      onChange,
      onBlur,
      autoComplete = 'off',
      placeholder = '$',
      defaultValue,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const [inputValue, setInputValue] = useState(round(value ?? defaultValue))
    const imaskRef = useRef<any>(null)
    useEffect(() => {
      if (
        imaskRef.current &&
        imaskRef.current.maskRef.unmaskedValue !==
          toString(value ?? defaultValue)
      ) {
        imaskRef.current.element.value = toString(value ?? defaultValue)
        imaskRef.current.maskRef.updateValue()
        imaskRef.current.maskRef.updateControl()
      }
    }, [value ?? defaultValue])

    return (
      <IMaskInput
        ref={mergeRefs([imaskRef, ref])}
        mask="$num"
        blocks={{
          num: {
            mask: Number,
            scale: 2,
            padFractionalZeros: true,
            thousandsSeparator: ',',
            radix: '.',
            min: Number.MIN_SAFE_INTEGER,
            max: Number.MAX_SAFE_INTEGER,
          },
        }}
        unmask="typed"
        type="text"
        defaultValue={round(value ?? defaultValue)}
        autoComplete={autoComplete}
        className={clsx(styles.input, className)}
        onAccept={(value, mask, event) => {
          setInputValue(mask.unmaskedValue as string)
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = mask.unmaskedValue as string
            onChange &&
              onChange(event as unknown as ChangeEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        onBlur={(event) => {
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = inputValue as string
            onBlur && onBlur(event as unknown as FocusEvent<HTMLInputElement>)
            target.value = currentValue === '$' ? '' : currentValue
          }
        }}
        placeholder={placeholder}
        {...rest}
      />
    )
  }
)
InputCurrency.displayName = 'InputCurrency'

export default InputCurrency
